<template>
  <div class="summary-container">
    <div class="summary-tabs">
      <button 
        @click="activeTab = 'daily'" 
        :class="{ active: activeTab === 'daily' }"
      >
        Daily
      </button>
      <button 
        @click="activeTab = 'weekly'" 
        :class="{ active: activeTab === 'weekly' }"
      >
        Weekly
      </button>
      <button 
        @click="activeTab = 'monthly'" 
        :class="{ active: activeTab === 'monthly' }"
      >
        Monthly
      </button>
    </div>
    
    <div class="summary-content">
      <div v-if="activeTab === 'daily'" class="daily-summary">
        <h3>Today's Work Summary</h3>
        <div class="summary-stats">
          <div class="stat-item">
            <div class="stat-label">Check-in Time</div>
            <div class="stat-value">{{ todaySummary.checkInTime || 'N/A' }}</div>
          </div>
          <div class="stat-item">
            <div class="stat-label">Check-out Time</div>
            <div class="stat-value">{{ todaySummary.checkOutTime || 'N/A' }}</div>
          </div>
          <div class="stat-item">
            <div class="stat-label">Lunch Duration</div>
            <div class="stat-value">{{ todaySummary.lunchDuration || 'N/A' }}</div>
          </div>
          <div class="stat-item">
            <div class="stat-label">Total Work Hours</div>
            <div class="stat-value">{{ todaySummary.totalHours || 'N/A' }}</div>
          </div>
          <div class="stat-item">
            <div class="stat-label">Schedule Adherence</div>
            <div class="stat-value" :class="todaySummary.adherenceClass">
              {{ todaySummary.adherence || 'N/A' }}
            </div>
          </div>
        </div>
      </div>
      
      <div v-if="activeTab === 'weekly'" class="weekly-summary">
        <h3>This Week's Work Summary</h3>
        <div class="summary-chart">
          <div 
            v-for="(day, index) in weeklySummary" 
            :key="index" 
            class="chart-day"
          >
            <div class="day-name">{{ day.name }}</div>
            <div class="day-bar-container">
              <div 
                class="day-bar" 
                :style="{ height: day.hoursPercentage + '%' }"
                :class="{ 'no-hours': !day.hours }"
              >
                {{ day.hours ? day.hours + 'h' : 'N/A' }}
              </div>
            </div>
          </div>
        </div>
        <div class="weekly-totals">
          <div class="total-item">
            <div class="total-label">Total Hours</div>
            <div class="total-value">{{ weeklyTotal.hours }}h</div>
          </div>
          <div class="total-item">
            <div class="total-label">Average Daily</div>
            <div class="total-value">{{ weeklyTotal.average }}h</div>
          </div>
          <div class="total-item">
            <div class="total-label">Overtime</div>
            <div class="total-value" :class="{ 'overtime': weeklyTotal.overtime > 0 }">
              {{ weeklyTotal.overtime }}h
            </div>
          </div>
        </div>
      </div>
      
      <div v-if="activeTab === 'monthly'" class="monthly-summary">
        <h3>Monthly Work Summary</h3>
        <p>Detailed monthly reports are available for download.</p>
        <button class="download-button" @click="downloadReport('csv')">Download CSV</button>
        <button class="download-button" @click="downloadReport('pdf')">Download PDF</button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import workforceService from '@/services/workforceService';

export default {
  name: 'WorkforceSummary',
  data() {
    return {
      activeTab: 'daily',
      todaySummary: {
        checkInTime: null,
        checkOutTime: null,
        lunchDuration: null,
        totalHours: null,
        adherence: null,
        adherenceClass: null
      },
      weeklySummary: [],
      monthlySummary: {
        totalHours: 0,
        daysWorked: 0,
        averageHours: 0,
        onTimeRate: 0
      },
      loading: {
        daily: false,
        weekly: false,
        monthly: false
      },
      error: null
    }
  },
  methods: {
    async fetchTodaySummary() {
      this.loading.daily = true;
      this.error = null;
      
      try {
        // Get all attendance records for today
        const allAttendanceToday = await workforceService.getAllTodayAttendance();
        // Get today's schedule data
        const schedules = await workforceService.getUserSchedule(
          new Date().getMonth(), 
          new Date().getFullYear()
        );
        
        // Find today's schedule
        const today = new Date();
        const todaySchedule = schedules.find(schedule => {
          const scheduleDate = schedule.date.toDate();
          return scheduleDate.getDate() === today.getDate() &&
                 scheduleDate.getMonth() === today.getMonth() &&
                 scheduleDate.getFullYear() === today.getFullYear();
        });
        
        if (allAttendanceToday.length > 0) {
          // Calculate total hours worked across all shifts today
          let totalWorkedHours = 0;
          let checkInTimes = [];
          let checkOutTimes = [];
          let totalLunchMinutes = 0;
          
          allAttendanceToday.forEach(record => {
            if (record.totalWorkHours) {
              totalWorkedHours += record.totalWorkHours;
            } else if (record.checkInTime && record.checkOutTime) {
              const shiftHours = (record.checkOutTime.toDate() - record.checkInTime.toDate()) / (1000 * 60 * 60);
              totalWorkedHours += shiftHours;
            }
            
            if (record.checkInTime) {
              checkInTimes.push(record.checkInTime.toDate().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
            }
            
            if (record.checkOutTime) {
              checkOutTimes.push(record.checkOutTime.toDate().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
            }
            
            if (record.lunchStartTime && record.lunchEndTime) {
              const lunchMinutes = Math.round(
                (record.lunchEndTime.toDate() - record.lunchStartTime.toDate()) / (1000 * 60)
              );
              totalLunchMinutes += lunchMinutes;
            }
          });
          
          // Format check-in times as a list
          const checkInTime = checkInTimes.length ? checkInTimes.join(', ') : null;
          
          // Format check-out times as a list
          const checkOutTime = checkOutTimes.length ? checkOutTimes.join(', ') : null;
          
          // Format lunch duration
          let lunchDuration = totalLunchMinutes > 0 ? `${totalLunchMinutes} min` : null;
          
          // Calculate adherence for split shifts
          let adherence = 'N/A';
          let adherenceClass = 'neutral';
          
          // For the adherence calculation with split shifts, we need to do a more complex comparison
          if (todaySchedule && todaySchedule.timeSlots && todaySchedule.timeSlots.length > 0) {
            // Match each time slot to an attendance record
            const timeSlots = todaySchedule.timeSlots;
            
            // Count missed shifts, late check-ins, and early check-outs
            let missedShifts = 0;
            let lateCheckIns = 0;
            let earlyCheckOuts = 0;
            
            timeSlots.forEach(slot => {
              // Parse slot times
              const startParts = slot.startTime.split(':');
              const slotStart = new Date();
              slotStart.setHours(parseInt(startParts[0]), parseInt(startParts[1]), 0, 0);
              
              const endParts = slot.endTime.split(':');
              const slotEnd = new Date();
              slotEnd.setHours(parseInt(endParts[0]), parseInt(endParts[1]), 0, 0);
              
              // Find a matching attendance record for this slot
              const matchingAttendance = allAttendanceToday.find(record => {
                const checkInTime = record.checkInTime ? record.checkInTime.toDate() : null;
                // Consider a record matching if check-in is within 2 hours of slot start
                return checkInTime && 
                      Math.abs(checkInTime - slotStart) < (2 * 60 * 60 * 1000);
              });
              
              if (!matchingAttendance) {
                // No attendance record for this slot
                missedShifts++;
              } else {
                const checkInTime = matchingAttendance.checkInTime.toDate();
                
                if (checkInTime > new Date(slotStart.getTime() + 15 * 60 * 1000)) {
                  lateCheckIns++;
                }
                
                if (matchingAttendance.checkOutTime) {
                  const checkOutTime = matchingAttendance.checkOutTime.toDate();
                  if (checkOutTime < new Date(slotEnd.getTime() - 15 * 60 * 1000)) {
                    earlyCheckOuts++;
                  }
                }
              }
            });
            
            // Determine overall adherence
            if (missedShifts > 0) {
              adherence = `Missed ${missedShifts} shift${missedShifts > 1 ? 's' : ''}`;
              adherenceClass = 'poor-adherence';
            } else if (lateCheckIns > 0 || earlyCheckOuts > 0) {
              const issues = [];
              if (lateCheckIns > 0) issues.push(`Late: ${lateCheckIns}`);
              if (earlyCheckOuts > 0) issues.push(`Early Out: ${earlyCheckOuts}`);
              adherence = issues.join(', ');
              adherenceClass = 'warning-adherence';
            } else {
              adherence = 'Good';
              adherenceClass = 'good-adherence';
            }
          }
          
          this.todaySummary = {
            checkInTime,
            checkOutTime,
            lunchDuration,
            totalHours: totalWorkedHours.toFixed(2),
            adherence,
            adherenceClass,
            shiftsCompleted: allAttendanceToday.filter(record => record.checkOutTime).length,
            totalShifts: todaySchedule && todaySchedule.timeSlots ? todaySchedule.timeSlots.length : 1
          };
        }
      } catch (error) {
        console.error('Error fetching today\'s summary:', error);
        this.error = 'Failed to load today\'s summary.';
      } finally {
        this.loading.daily = false;
      }
    },
    
    async fetchWeeklySummary() {
      this.loading.weekly = true;
      this.error = null;
      
      try {
        const startOfWeek = moment().startOf('week').toDate();
        const endOfWeek = moment().endOf('week').toDate();
        
        const weeklyAttendance = await workforceService.getUserAttendanceHistory(startOfWeek, endOfWeek);
        
        // Initialize the week with empty days
        const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const summary = weekDays.map((name, index) => {
          return {
            name,
            hours: 0,
            date: moment().day(index).format('MMM D'),
            hasHours: false
          };
        });
        
        // Fill in data from attendance records
        weeklyAttendance.forEach(record => {
          const recordDate = record.date.toDate();
          const dayOfWeek = recordDate.getDay(); // 0 = Sunday, 1 = Monday, etc.
          
          summary[dayOfWeek].hours = record.totalWorkHours || 0;
          summary[dayOfWeek].hasHours = record.totalWorkHours > 0;
        });
        
        this.weeklySummary = summary;
      } catch (error) {
        console.error('Error fetching weekly summary:', error);
        this.error = 'Failed to load weekly summary.';
      } finally {
        this.loading.weekly = false;
      }
    },
    
    async fetchMonthlySummary() {
      this.loading.monthly = true;
      this.error = null;
      
      try {
        const startOfMonth = moment().startOf('month').toDate();
        const endOfMonth = moment().endOf('month').toDate();
        
        const monthlyAttendance = await workforceService.getUserAttendanceHistory(startOfMonth, endOfMonth);
        
        let totalHours = 0;
        let daysWorked = 0;
        let onTimeDays = 0;
        
        monthlyAttendance.forEach(record => {
          if (record.totalWorkHours) {
            totalHours += record.totalWorkHours;
            daysWorked++;
            
            // Check if check-in was on time (this is just an example)
            // In a real app, you'd compare with scheduled hours
            if (record.status !== 'Late') {
              onTimeDays++;
            }
          }
        });
        
        this.monthlySummary = {
          totalHours: totalHours.toFixed(2),
          daysWorked,
          averageHours: daysWorked > 0 ? (totalHours / daysWorked).toFixed(2) : 0,
          onTimeRate: daysWorked > 0 ? Math.round((onTimeDays / daysWorked) * 100) : 0
        };
      } catch (error) {
        console.error('Error fetching monthly summary:', error);
        this.error = 'Failed to load monthly summary.';
      } finally {
        this.loading.monthly = false;
      }
    },
    
    downloadSummary(format) {
      // Implement download functionality
      alert(`Downloading summary in ${format} format...`);
    },
    
    formatHours(hours) {
      if (!hours) return '0:00';
      
      const wholeHours = Math.floor(hours);
      const minutes = Math.round((hours - wholeHours) * 60);
      
      return `${wholeHours}:${minutes.toString().padStart(2, '0')}`;
    }
  },
  watch: {
    activeTab(newTab) {
      if (newTab === 'daily') {
        this.fetchTodaySummary();
      } else if (newTab === 'weekly') {
        this.fetchWeeklySummary();
      } else if (newTab === 'monthly') {
        this.fetchMonthlySummary();
      }
    }
  },
  mounted() {
    this.fetchTodaySummary();
  }
}
</script>

<style scoped>
.summary-container {
  width: 100%;
}

.summary-tabs {
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 20px;
}

.summary-tabs button {
  padding: 10px 20px;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: bold;
  color: #757575;
}

.summary-tabs button.active {
  color: #2196F3;
  border-bottom: 2px solid #2196F3;
}

.summary-stats {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
  margin-top: 20px;
}

.stat-item {
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 8px;
}

.stat-label {
  font-size: 0.9em;
  color: #757575;
  margin-bottom: 5px;
}

.stat-value {
  font-size: 1.2em;
  font-weight: bold;
}

.good-adherence {
  color: #4CAF50;
}

.warning-adherence {
  color: #FF9800;
}

.poor-adherence {
  color: #F44336;
}

.summary-chart {
  display: flex;
  height: 200px;
  justify-content: space-between;
  align-items: flex-end;
  margin: 20px 0;
}

.chart-day {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 13%;
}

.day-name {
  margin-bottom: 5px;
}

.day-bar-container {
  width: 100%;
  height: 150px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.day-bar {
  width: 80%;
  background-color: #2196F3;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  min-height: 30px;
}

.day-bar.no-hours {
  background-color: #e0e0e0;
  color: #757575;
}

.weekly-totals {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  padding: 15px;
  border-radius: 8px;
}

.total-item {
  text-align: center;
}

.total-label {
  font-size: 0.9em;
  color: #757575;
  margin-bottom: 5px;
}

.total-value {
  font-size: 1.2em;
  font-weight: bold;
}

.overtime {
  color: #F44336;
}

.download-button {
  padding: 10px 20px;
  background-color: #2196F3;
  color: white;
  border: none;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
  margin-top: 15px;
}

.download-button:hover {
  background-color: #1976D2;
}
</style> 