<template>
  <v-container v-bind:class="{ 'pa-0': $vuetify.breakpoint.smAndDown }">
    <div class="user-actions" v-if="!isAdmin">

      <div class="hero-section2">
        <v-card class="hero-card">
          <div class="hero-content pt-0">
            <div class="user-welcome">
              <span class="greeting">
                <!-- greeting hand emoji -->
                Hola,</span>
              <span class="user-name">{{ $store.state.Auth.token.claims.name.split(" ")[0] }}</span>
            </div>
            <div class="motivation-text">
              <!-- rocket emoji -->
              Recuerda que debes marcar tu entrada y salida cada día.
              <span class="rocket-emoji">🚀</span>
            </div>
          </div>
        </v-card>
      </div>
    <!--   <v-card v-if="loading" class="mx-auto rounded-xl overflow-hidden" style="margin-top: -30px;" max-width="360px" >
              <v-skeleton-loader  type="image"></v-skeleton-loader>
      </v-card> -->
      <v-card  class="time-tracker-container"  :loading="loading" :disabled="loading">
        <div class="time-tracker-header">
          <div class="user-greeting">{{ userGreeting }}</div>
        </div>
        
        <div class="timer-display" :class="{'break-time': currentStatus === 'On Lunch', 'break-time-warning': breakTimeExceeded}">
          <div class="timer">{{ currentStatus === 'On Lunch' ? formattedBreakTime : formattedWorkTime }}</div>
          <div class="timer-labels">
            <span>H</span>
            <span>M</span>
            <span>S</span>
          </div>
        </div>
        
        <div class="date-display">{{ formattedDate }}</div>
        
        <v-card class="shift-info-card" outlined>
          <div class="shift-info">
            <v-icon left size="18" color="primary">mdi-calendar-clock</v-icon>
            <span class="shift-label">Turno</span>
            <div class="shift-time">{{ formattedShiftTime }}</div>
          </div>
          
          <div class="break-info" v-if="currentStatus === 'On Lunch'">
            <v-icon left size="18" :color="breakTimeExceeded ? 'error' : 'warning'">mdi-clock-alert-outline</v-icon>
            <span>Tiempo de descanso permitido: {{ allowedBreakTime }} minutos</span>
          </div>
          
          <div class="last-check-info" v-if="lastCheckTime">
            <div>Ultima marca de entrada: {{ lastCheckTime }}</div>
          </div>
        </v-card>
        
        <div class="action-buttons">
          <v-btn  large
            @click="checkIn" 
            :disabled="currentStatus !== 'Not Checked In' || loading || noMoreCheckInsAllowed"
            :loading="actionLoading === 'checkIn'"
            color="amber darken-2"
            class="action-button entrada-button"
            v-if="currentStatus === 'Not Checked In'">
            <v-icon left>mdi-login</v-icon>
            Marcar Entrada
          </v-btn>
          
          <v-btn  large
            @click="goToLunch" 
            :disabled="currentStatus !== 'Working' || loading"
            :loading="actionLoading === 'lunch'"
            color="warning"
            class="action-button lunch-button"
            v-if="currentStatus === 'Working'">
            <v-icon left>mdi-pause</v-icon>
            Pausa
          </v-btn>
          
          <v-btn  large
            @click="resumeWork" 
            :disabled="currentStatus !== 'On Lunch' || loading"
            :loading="actionLoading === 'resume'"
            color="info"
            class="action-button resume-button"
            v-if="currentStatus === 'On Lunch'">
            <v-icon left>mdi-play</v-icon>
            Reanudar
          </v-btn>
          
          <v-btn  large
            @click="showCheckoutConfirmation = true" 
            :disabled="currentStatus !== 'Working' || loading"
            :loading="actionLoading === 'checkOut'"
            color="white"
            class="action-button salida-button"
            outlined
            v-if="currentStatus === 'Working' || currentStatus === 'On Lunch'">
            <v-icon left>mdi-logout</v-icon>
            Marcar Salida
          </v-btn>
        </div>
      </v-card>
    </div>

    <v-card class="rounded-lg overflow-hidden" elevation="2" v-if="isAdmin">
      <v-card-title>
        <v-icon left color="primary">mdi-account-group-outline</v-icon>
        Fuerza de trabajo
      </v-card-title>
    
    <v-alert v-if="error" type="error" dismissible>
      {{ error }}
    </v-alert>
    
    
    
    <div class="admin-panel" v-if="isAdmin">
      <WorkforceAdminPanel @error="handleError" />
    </div>
 <!--    
    <div class="user-schedule" v-if="!isAdmin">
      <h2>My Schedule</h2>
      <WorkforceCalendar />
    </div>
     -->
   <!--  <div class="work-summary" v-if="!isAdmin">
      <h2>Work Hours Summary</h2>
      <WorkforceSummary />
    </div> -->
  </v-card>

  <!-- Checkout Confirmation Modal -->
  <v-dialog v-model="showCheckoutConfirmation" max-width="400">
    <v-card>
      <v-card-title class="headline">Confirmar Salida</v-card-title>
      <v-card-text>
        ¿Estás seguro que deseas marcar tu salida? Esta acción no se puede deshacer.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" text @click="showCheckoutConfirmation = false">
          Cancelar
        </v-btn>
        <v-btn color="red darken-1" text @click="confirmCheckOut">
          Confirmar Salida
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </v-container>


 
</template>

<script>
import WorkforceAdminPanel from './WorkforceAdminPanel.vue';
import WorkforceCalendar from './WorkforceCalendar.vue';
import WorkforceSummary from './WorkforceSummary.vue';
import workforceService from '@/services/workforceService';

import { Timestamp } from 'firebase/firestore';
import moment from 'moment';

export default {
  name: 'WorkforceManagement',
  components: {
    WorkforceAdminPanel,
    WorkforceCalendar,
    WorkforceSummary
  },
  data() {
    return {
      currentStatus: 'Not Checked In', // Possible values: 'Not Checked In', 'Working', 'On Lunch', 'Checked Out'
      statusMessage: 'You have not checked in yet.',
      checkInTime: null,
      checkOutTime: null,
      lunchStartTime: null,
      lunchEndTime: null,
      isAdmin: false, // This should be determined based on user role
      loading: true,
      error: null,
      actionLoading: null, // 'checkIn', 'lunch', 'resume', 'checkOut'
      noMoreCheckInsAllowed: false,
      totalScheduledShifts: 0,
      completedShifts: 0,
      timerInterval: null,
      workTimeInSeconds: 0,
      breakTimeInSeconds: 0,
      allowedBreakTime: 30, // Default allowed break time in minutes
      lastCheckTime: null,
      currentScheduledShift: null,
      showCheckoutConfirmation: false,
      lastUpdateTime: null // Add this to track last update time
    }
  },
  computed: {
    userGreeting() {
      
      if (this.currentStatus === 'On Lunch') {
        return `Llleva en descanso`;
      }
      
      return `Lleva trabajando`;
    },
    formattedWorkTime() {
      if (this.currentStatus !== 'Working') {
        return '00:00:00';
      }
      
      const hours = Math.floor(this.workTimeInSeconds / 3600);
      const minutes = Math.floor((this.workTimeInSeconds % 3600) / 60);
      const seconds = this.workTimeInSeconds % 60;
      
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    },
    formattedBreakTime() {
      const hours = Math.floor(this.breakTimeInSeconds / 3600);
      const minutes = Math.floor((this.breakTimeInSeconds % 3600) / 60);
      const seconds = this.breakTimeInSeconds % 60;
      
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    },
    breakTimeExceeded() {
      if (this.currentStatus !== 'On Lunch' || !this.lunchStartTime) {
        return false;
      }
      
      // Check if break time exceeds allowed break time
      return this.breakTimeInSeconds > (this.allowedBreakTime * 60);
    },
    formattedDate() {
      const days = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
      const months = ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'];
      
      const now = new Date();
      const dayName = days[now.getDay()];
      const day = now.getDate();
      const month = months[now.getMonth()];
      const year = now.getFullYear();
      
      return `${dayName} ${day}, ${month} ${year}`;
    },
    formattedShiftTime() {
      if (this.totalScheduledShifts > 0 && this.currentScheduledShift) {
        const { startTime, endTime, breakDuration } = this.currentScheduledShift;
        this.allowedBreakTime = breakDuration || 30;
        return `${startTime} - ${endTime} (${breakDuration} minutos)`;
      }
      return '07:00 - 14:00 (30 minutos)'; // Default value if no shift info
    }
  },
  methods: {
    startTimer() {
      if (this.timerInterval) {
        clearInterval(this.timerInterval);
      }
      
      this.workTimeInSeconds = 0;
      this.breakTimeInSeconds = 0;
      
      if (this.checkInTime) {
        this.recalculateTimer();
      }
      
      this.lastUpdateTime = new Date();
      this.timerInterval = setInterval(() => {
        if (this.currentStatus === 'Working') {
          this.workTimeInSeconds++;
        } else if (this.currentStatus === 'On Lunch') {
          this.breakTimeInSeconds++;
        }
      }, 1000);
    },

    recalculateTimer() {
      const now = new Date();
      
      if (this.currentStatus === 'Working' && this.checkInTime) {
        let elapsedTime = Math.floor((now - this.checkInTime) / 1000);
        
        // Subtract lunch time if applicable
        if (this.lunchStartTime && this.lunchEndTime) {
          const lunchTime = Math.floor((this.lunchEndTime - this.lunchStartTime) / 1000);
          elapsedTime -= lunchTime;
        }
        
        this.workTimeInSeconds = Math.max(0, elapsedTime);
      } else if (this.currentStatus === 'On Lunch' && this.lunchStartTime) {
        const breakTime = Math.floor((now - this.lunchStartTime) / 1000);
        this.breakTimeInSeconds = Math.max(0, breakTime);
      }
      
      this.lastUpdateTime = now;
    },
    
    handleVisibilityChange() {
      if (document.visibilityState === 'visible') {
        // Page is now visible (user has refocused)
        if (this.currentStatus === 'Working' || this.currentStatus === 'On Lunch') {
          this.recalculateTimer();
        }
      }
    },
    updateLastCheckTime() {
      if (this.checkInTime) {
        const formattedTime = this.formatTime(this.checkInTime);
        this.lastCheckTime = `${formattedTime} (${moment(this.checkInTime).format('MMM/DD')})`;
      } else {
        this.lastCheckTime = null;
      }
    },
    async checkIn() {
      try {
        this.actionLoading = 'checkIn';
        this.error = null;
        
        // Get current location
        const position = await this.getCurrentPosition();
        
        // Record check-in
        try {
          // Pass flag indicating if this is an unscheduled check-in
          const result = await workforceService.recordCheckIn(
            position, 
            null, 
            false, 
            this.totalScheduledShifts === 0
          );
          
          // Update component state
          this.checkInTime = new Date();
          this.currentStatus = 'Working';
          
          if (this.totalScheduledShifts === 0) {
            this.statusMessage = `Checked in at ${this.formatTime(this.checkInTime)} (Unscheduled shift)`;
          } else {
            this.statusMessage = `Checked in at ${this.formatTime(this.checkInTime)}`;
          }
          
          this.updateLastCheckTime();
          this.startTimer();
        } catch (error) {
          // If this is an early check-in warning, show it but allow override
          if (error.message && error.message.includes("minutes early")) {
            const shouldContinue = confirm(`${error.message}\n\nDo you want to check in anyway?`);
            
            if (shouldContinue) {
              // Force check-in by setting an override flag
              const result = await workforceService.recordCheckIn(
                position, 
                null, 
                true, 
                this.totalScheduledShifts === 0
              );
              
              // Update component state
              this.checkInTime = new Date();
              this.currentStatus = 'Working';
              
              if (this.totalScheduledShifts === 0) {
                this.statusMessage = `Checked in at ${this.formatTime(this.checkInTime)} (Unscheduled shift, Early check-in)`;
              } else {
                this.statusMessage = `Checked in at ${this.formatTime(this.checkInTime)} (Early check-in)`;
              }
              
              this.updateLastCheckTime();
              this.startTimer();
            } else {
              throw new Error("Check-in cancelled");
            }
          } else {
            // For other errors, just rethrow
            throw error;
          }
        }
        
        this.actionLoading = null;
      } catch (error) {
        console.error('Check-in failed:', error);
        this.error = error.message || 'Failed to check in. Please try again.';
        this.actionLoading = null;
      }
    },
    
    async goToLunch() {
      try {
        this.actionLoading = 'lunch';
        this.error = null;
        
        const result = await workforceService.startLunch();
        
        this.lunchStartTime = result.lunchStartTime.toDate();
        this.currentStatus = 'On Lunch';
        this.statusMessage = `Descanso comenzó a las ${this.formatTime(this.lunchStartTime)}`;
        this.breakTimeInSeconds = 0;
        
        // Make sure we track break time accurately
        if (this.timerInterval) {
          clearInterval(this.timerInterval);
        }
        
        this.timerInterval = setInterval(() => {
          this.breakTimeInSeconds++;
        }, 1000);
        
        this.actionLoading = null;
      } catch (error) {
        console.error('Start lunch failed:', error);
        this.error = error.message || 'Failed to start lunch. Please try again.';
        this.actionLoading = null;
      }
    },
    
    async resumeWork() {
      try {
        this.actionLoading = 'resume';
        this.error = null;
        
        const result = await workforceService.endLunch();
        
        this.lunchEndTime = result.lunchEndTime.toDate();
        this.currentStatus = 'Working';
        this.statusMessage = `Regresó del descanso a las ${this.formatTime(this.lunchEndTime)}`;
        
        // Restart the timer to track work time
        this.startTimer();
        this.actionLoading = null;
      } catch (error) {
        console.error('Resume work failed:', error);
        this.error = error.message || 'Failed to resume work. Please try again.';
        this.actionLoading = null;
      }
    },
    
    async checkOut() {
      try {
        this.actionLoading = 'checkOut';
        this.error = null;
        
        // Get current location
        const position = await this.getCurrentPosition();
        
        // Record check-out
        const result = await workforceService.recordCheckOut(position);
        
        // Update component state
        this.checkOutTime = result.checkOutTime.toDate();
        this.currentStatus = 'Checked Out';
        this.statusMessage = `Checked out at ${this.formatTime(this.checkOutTime)}. Total hours: ${result.totalWorkHours.toFixed(2)}`;
        
        // Clear timer
        if (this.timerInterval) {
          clearInterval(this.timerInterval);
          this.timerInterval = null;
        }
        
        this.actionLoading = null;
      } catch (error) {
        console.error('Check-out failed:', error);
        this.error = error.message || 'Failed to check out. Please try again.';
        this.actionLoading = null;
      }
    },
    
    calculateWorkHours() {
      if (!this.checkInTime || !this.checkOutTime) return 0;
      
      let totalMs = this.checkOutTime - this.checkInTime;
      
      // Subtract lunch time if applicable
      if (this.lunchStartTime && this.lunchEndTime) {
        totalMs -= (this.lunchEndTime - this.lunchStartTime);
      }
      
      // Convert to hours
      return totalMs / (1000 * 60 * 60);
    },
    
    formatTime(date) {
      if (!date) return '';
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    },
    
    getCurrentPosition() {
      return new Promise((resolve, reject) => {
        if (!navigator.geolocation) {
          reject(new Error('Geolocation is not supported by your browser'));
          return;
        }
        
        navigator.geolocation.getCurrentPosition(
          position => {
            resolve({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            });
          },
          error => {
            reject(error);
          }
        );
      });
    },
    
    async loadTodayAttendance() {
      try {
        // Get all of today's attendance records for the user (not just active ones)
        const allAttendanceToday = await workforceService.getAllTodayAttendance();
        
        // Count completed shifts (with checkout)
        const completedShifts = allAttendanceToday.filter(record => record.checkOutTime).length;
        this.completedShifts = completedShifts;
        
        // Get today's schedule to check the number of allowed shifts
        try {
          const todayStart = moment().startOf('day').toDate();
          const todayEnd = moment().endOf('day').toDate();
          
          const schedules = await workforceService.getUserSchedule(
            new Date().getMonth(), 
            new Date().getFullYear()
          );
          
          // Find today's schedule
          const todaySchedule = schedules.find(schedule => {
            const scheduleDate = schedule.date.toDate();
            return scheduleDate >= todayStart && scheduleDate <= todayEnd;
          });
          
          if (todaySchedule) {
            const timeSlots = todaySchedule.timeSlots || [{
              startTime: todaySchedule.startTime || '09:00',
              endTime: todaySchedule.endTime || '17:00',
              breakDuration: todaySchedule.breakDuration || 60
            }];
            
            this.totalScheduledShifts = timeSlots.length;
            this.currentScheduledShift = timeSlots[0]; // Take the first shift for display
            
            // Set allowed break time from schedule
            if (timeSlots[0].breakDuration) {
              this.allowedBreakTime = timeSlots[0].breakDuration;
            }
            
            // Allow check-in even if all shifts are completed
            this.noMoreCheckInsAllowed = false;
            
            // Display scheduled shifts and break information
            if (allAttendanceToday.length === 0) {
              const breakInfo = timeSlots.map(slot => `${slot.breakDuration} min break`).join(', ');
              const shiftInfo = timeSlots.map(slot => `${slot.startTime}-${slot.endTime}`).join(', ');
              this.statusMessage = `Scheduled shifts today: ${shiftInfo} with ${breakInfo}`;
            }
            
            console.log(`Completed ${completedShifts} of ${timeSlots.length} scheduled shifts`);
          } else {
            // No schedule found for today, but still allow check-in
            this.noMoreCheckInsAllowed = false;
            this.totalScheduledShifts = 0;
            
            if (allAttendanceToday.length === 0) {
              this.statusMessage = 'No shifts scheduled for today. You can still check in but admin will be notified.';
            }
          }
        } catch (error) {
          console.error('Error fetching schedule:', error);
          // If we can't get the schedule, don't block check-in
          this.noMoreCheckInsAllowed = false;
          this.statusMessage = 'Unable to fetch schedule. You can still check in.';
        }
        
        if (allAttendanceToday.length > 0) {
          // Find the most recent attendance record
          const mostRecent = allAttendanceToday.reduce((latest, current) => {
            const latestTime = latest.checkInTime.toDate();
            const currentTime = current.checkInTime.toDate();
            return currentTime > latestTime ? current : latest;
          }, allAttendanceToday[0]);
          
          // Update status based on most recent record
          const attendance = mostRecent;
          
          if (!attendance.checkOutTime) {
            // User is currently checked in
            this.currentStatus = attendance.lunchStartTime && !attendance.lunchEndTime ? 'On Lunch' : 'Working';
            this.checkInTime = attendance.checkInTime.toDate();
            
            if (attendance.lunchStartTime && !attendance.lunchEndTime) {
              this.lunchStartTime = attendance.lunchStartTime.toDate();
              this.statusMessage = `Descanso comenzó a las ${this.formatTime(this.lunchStartTime)}`;
              
              // Get break duration from attendance record if available
              if (attendance.breakDuration) {
                this.allowedBreakTime = attendance.breakDuration;
              } else if (attendance.scheduledShift && attendance.scheduledShift.breakDuration) {
                this.allowedBreakTime = attendance.scheduledShift.breakDuration;
              }
            } else {
              this.statusMessage = `Working since ${this.formatTime(this.checkInTime)}`;
              
              // Add break information if available
              if (!attendance.isUnscheduled) {
                // Get scheduled shift information
                const breakDuration = attendance.breakDuration || 60;
                if (attendance.scheduledShift) {
                  const { startTime, endTime } = attendance.scheduledShift;
                  this.statusMessage += ` (Shift: ${startTime}-${endTime}, ${breakDuration} min break available)`;
                  this.allowedBreakTime = breakDuration;
                } else {
                  this.statusMessage += ` (${breakDuration} min break available)`;
                  this.allowedBreakTime = breakDuration;
                }
              } else if (attendance.isUnscheduled) {
                this.statusMessage += ' (Unscheduled shift)';
              }
            }
            
            this.updateLastCheckTime();
            this.startTimer();
          } else {
            // User has checked out of their last shift
            this.currentStatus = 'Not Checked In';
            this.checkInTime = null;
            this.checkOutTime = null;
            this.lunchStartTime = null;
            this.lunchEndTime = null;
            
            // Show message about completed shifts
            if (this.totalScheduledShifts > 0) {
              this.statusMessage = `You have completed ${this.completedShifts} of ${this.totalScheduledShifts} shift${this.totalScheduledShifts > 1 ? 's' : ''} today. You can check in again if needed.`;
            } else {
              this.statusMessage = `You have completed ${this.completedShifts} shift${this.completedShifts > 1 ? 's' : ''} today without any scheduled shifts.`;
            }
          }
        } else {
          // No attendance records for today
          this.currentStatus = 'Not Checked In';
          if (this.totalScheduledShifts > 0) {
            // Status message already set above with shift details
          } else {
            // Status message already set above for no scheduled shifts
          }
        }
      } catch (error) {
        console.error('Error loading today\'s attendance:', error);
        this.error = 'Failed to load attendance data.';
      }
    },
    
    checkIfUserIsAdmin() {
      // Check if user has admin role
      const userClaims = this.$store.state.Auth.token?.claims;
      if (userClaims && (userClaims.type === 'admin' || userClaims.type === 'superuser')) {
        this.isAdmin = true;
      } else {
        this.isAdmin = false;
      }
    },
    
    handleError(message) {
      this.error = message;
    },

    confirmCheckOut() {
      this.showCheckoutConfirmation = false;
      this.checkOut();
    }
  },
  async mounted() {
    this.loading = true;
    this.checkIfUserIsAdmin();
    await this.loadTodayAttendance();
    this.loading = false;
    
    // Add visibility change event listener
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  },
  beforeDestroy() {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
    
    // Remove visibility change event listener
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  }
}
</script>

<style scoped>
.time-tracker-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  border-radius: 30px;
  padding: 24px 20px;
  max-width: 360px;
  margin: 0 auto;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
  margin-top: -30px;
}

.time-tracker-header {
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
}

.app-title {
  font-weight: 600;
  letter-spacing: 1.5px;
  font-size: 18px;
  margin-bottom: 20px;
}

.user-greeting {
  font-size: 16px;
  font-weight: 400;
}

.timer-display {
  margin: 15px 0 20px;
  text-align: center;
}

.timer {
  font-size: 54px;
  font-weight: 700;
  letter-spacing: 2px;
  line-height: 1.1;
}

.timer-labels {
  display: flex;
  justify-content: space-between;
  width: 170px;
  margin: 5px auto 0;
}

.timer-labels span {
  width: 20px;
  text-align: center;
  font-size: 14px;
  opacity: 0.9;
}

.break-time .timer {
  color: #ffcc80; /* Light orange for break time */
}

.break-time-warning .timer {
  color: #ff5252 !important; /* Red for exceeded break time */
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0.7; }
  100% { opacity: 1; }
}

.date-display {
  margin-bottom: 20px;
  font-size: 14px;
  opacity: 0.9;
}

.shift-info-card {
  width: 100%;
  border-radius: 15px;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.shift-info {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-size: 14px;
}

.shift-label {
  margin-left: 5px;
  font-weight: 500;
}

.break-info {
  display: flex;
  align-items: center;
  margin: 10px 0;
  font-size: 13px;
  color: #555;
}

.shift-time {
  margin-left: auto;
  font-weight: 500;
}

.last-check-info {
  font-size: 12px;
  color: #777;
  margin-top: 10px;
  border-top: 1px solid #f0f0f0;
  padding-top: 10px;
}

.action-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 12px;
  margin-top: 10px;
}

.action-button {
  flex: 1;
  height: 44px;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  border-radius: 10px !important;
}

.entrada-button {
  background-color: #f5b324 !important;
}

.salida-button {
  border: 1.5px solid rgba(255, 255, 255, 0.8) !important;
  background-color: transparent !important;
}

.lunch-button {
  background-color: #FF9800 !important;
  box-shadow: 0 3px 8px rgba(255, 152, 0, 0.3) !important;
}

.resume-button {
  background-color: #2196F3 !important;
  box-shadow: 0 3px 8px rgba(33, 150, 243, 0.3) !important;
}

.hero-section {
  background: linear-gradient(135deg, var(--v-primary-base), var(--v-secondary-base));
  height: 240px;
  border-radius: 0 0 30px 30px;
  position: relative;
  padding: 24px;
}

.hero-section2 {
  background: linear-gradient(135deg, var(--v-primary-base), var(--v-secondary-base));
  height: 180px;
  border-radius: 0 0 30px 30px;
  position: relative;
  padding: 24px;
}

.hero-card {
  background: transparent !important;
  box-shadow: none !important;
}

.hero-content {
  color: white;
  padding-top: 20px;
}

.user-welcome {
  font-size: 2rem;
  margin-bottom: 8px;
}

.greeting {
  font-weight: 300;
}

.user-name {
  font-weight: 600;
  margin-left: 8px;
}

.motivation-text {
  font-size: 1.2rem;
  opacity: 0.9;
  font-weight: 300;
}

.progress-card {
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1) !important;
}

.section-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--v-primary-darken1);
}

.action-card {
  border-radius: 16px;
  transition: all 0.3s ease;
  height: 100px;
  cursor: pointer;
}

.action-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1) !important;
}

.action-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

.action-title {
  margin-top: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  text-align: center;
}

@media (max-width: 600px) {
  .hero-section {
    height: 200px;
  }

  .user-welcome {
    font-size: 1.75rem;
  }
}




</style> 