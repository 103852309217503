<template>
<v-container fluid class="admin-panel-container pa-0">
<v-card elevation="0" class="pa-0 ma-0">
  <v-divider />
  <v-tabs v-model="activeTab" :show-arrows="false">
    <v-tab>Empleados</v-tab>
    <v-tab>Turnos</v-tab>
    <v-tab>Agenda</v-tab>
    <v-tab>Gestión Asistencias</v-tab>
    <v-tab>Remuneraciones</v-tab>
    <v-tab>Alertas

      <v-chip class="ml-2" v-show="filteredAlerts.length > 0" x-small color="error">
        {{ this.filteredAlerts.length }}
      </v-chip>
    </v-tab>

    <!-- Users Tab -->
    <v-tab-item>
      <v-divider />
      <v-card flat>
        <v-card-title>
          Gestión de Empleados
          <v-spacer></v-spacer>
          <v-text-field v-model="userSearch" append-icon="mdi-magnify" label="Buscar empleados" single-line
            hide-details filled rounded dense class="rounded-lg"></v-text-field>
        </v-card-title>
        <v-divider />

        <v-data-table :headers="[
          { text: 'Nombre', value: 'name' },
          { text: 'Estado', value: 'status' },
          { text: 'Acciones', value: 'actions', sortable: false }
        ]" :items="filteredUsers" :search="userSearch" :loading="loading.users" class="elevation-1">
          <template #item.status="{ item }">
            <v-chip :color="getStatusColor(item.status)" text-color="white" small>
              {{ item.status }}
            </v-chip>
          </template>

          <template #item.actions="{ item }">
            <v-btn small @click="viewUserSchedule(item)">
              <v-icon small left>mdi-eye</v-icon>
              Ver
            </v-btn>

          </template>
        </v-data-table>
      </v-card>
    </v-tab-item>

    <!-- Shifts Tab -->
    <v-tab-item>
      <v-divider />
      <v-card flat>
        <v-card-title>
          Turnos Predefinidos
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="openShiftDialog()">
            <v-icon left>mdi-plus</v-icon>
            Nuevo Turno
          </v-btn>
        </v-card-title>
        <v-divider />

        <v-data-table :headers="[
          { text: 'Nombre', value: 'name' },
          { text: 'Hora de Inicio', value: 'startTime' },
          { text: 'Hora de Fin', value: 'endTime' },
          { text: 'Duración', value: 'duration' },
          { text: 'Descanso', value: 'breakInfo' },
          { text: 'Acciones', value: 'actions', sortable: false }
        ]" :items="shifts" :loading="loading.shifts" class="elevation-1">
          <template #item.duration="{ item }">
            {{ calculateDuration(item.startTime, item.endTime) }} horas
          </template>
          <template #item.breakInfo="{ item }">
            <v-chip v-if="item.hasBreak" color="success" small>
              {{ item.breakDuration }} min
            </v-chip>
            <v-chip v-else color="grey" small>
              Sin descanso
            </v-chip>
          </template>
          <template #item.actions="{ item }">
            <v-btn icon small class="mr-2" @click="openShiftDialog(item)">
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon small color="error" @click="confirmDeleteShift(item)">
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>

      <!-- Shift Dialog -->
      <v-dialog v-model="shiftDialog" max-width="500px">
        <v-card>
          <v-card-title>
            {{ editedShift.id ? 'Editar Turno' : 'Nuevo Turno' }}
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="editedShift.name" label="Nombre del Turno" outlined
                    required></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="editedShift.startTime" label="Hora de Inicio" type="time" outlined
                    required></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="editedShift.endTime" label="Hora de Fin" type="time" outlined
                    required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-switch v-model="editedShift.hasBreak" color="primary" label="Incluye Descanso"></v-switch>
                </v-col>
                <v-col cols="12" v-if="editedShift.hasBreak">
                  <v-text-field v-model.number="editedShift.breakDuration" label="Duración del Descanso (minutos)"
                    type="number" outlined required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea v-model="editedShift.description" label="Descripción (opcional)" outlined
                    rows="3"></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeShiftDialog">Cancelar</v-btn>
            <v-btn color="blue darken-1" text @click="saveShift" :loading="loading.shiftSave">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Delete Confirmation Dialog -->
      <v-dialog v-model="deleteShiftDialog" max-width="400px">
        <v-card>
          <v-card-title class="text-h6">¿Estás seguro de eliminar este turno?</v-card-title>
          <v-card-text>Esta acción no se puede deshacer.</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="deleteShiftDialog = false">Cancelar</v-btn>
            <v-btn color="error" text @click="deleteShift" :loading="loading.shiftDelete">Eliminar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-tab-item>

    <!-- Schedules Tab -->
    <v-tab-item>
      <v-divider />

      <v-card flat class="pa-0">

        <v-card-text v-if="selectedUser">
          <v-row>
            <v-col cols="12" md="8">
              <v-card outlined>
                <v-card-title>
                  {{ selectedUser.name }}
                  <v-chip :color="getStatusColor(selectedUser.status)" text-color="white" class="ml-4">
                    {{ selectedUser.status }}
                  </v-chip>
                </v-card-title>

                <v-card-text>
                  <AdminScheduleCalendar 
                    ref="adminCalendar" 
                    :userId="selectedUser.id"
                    @edit-schedule="handleEditSchedule"
                    @delete-schedule="handleDeleteSchedule"
                    @select-date="handleDateSelect"
                  />
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" md="4">
              <v-card outlined>
                <v-card-title>
                  <v-icon left v-if="scheduleTemplate.startDate === scheduleTemplate.endDate">mdi-calendar-edit</v-icon>
                  {{ scheduleTemplate.startDate === scheduleTemplate.endDate ? 'Editar Horario' : 'Editor de Agenda' }}
                </v-card-title>
                <v-card-text :class="{ 'edit-mode': scheduleTemplate.startDate === scheduleTemplate.endDate }">
                  <v-row>
                    <v-col cols="12">
                      <v-alert v-if="scheduleTemplate.startDate === scheduleTemplate.endDate && 
                                    scheduleTemplate.days.length > 0" 
                        type="info" text dense class="mb-2" color="info">
                        <v-icon left small v-if="scheduleTemplate.startDate === scheduleTemplate.endDate">
                          mdi-calendar-check
                        </v-icon>
                        Se ha seleccionado: {{ formatEditDate() }}
                      </v-alert>
                      
                      <v-card-subtitle>Seleccionar Días</v-card-subtitle>
                      <v-chip-group v-model="scheduleTemplate.days" multiple column>
                        <v-chip v-for="(day, index) in weekDays" :key="index" :value="index" filter outlined>
                          {{ day }}
                        </v-chip>
                      </v-chip-group>
                    </v-col>

                    <v-col cols="12">
                      <v-card-subtitle>Seleccionar Turno</v-card-subtitle>
                      <v-select v-model="selectedShift" :items="shifts" item-text="name" item-value="id"
                        label="Turno" outlined dense @change="applyShiftToSchedule">
                        <template #selection="{ item }">
                          {{ item.name }} ({{ item.startTime }} - {{ item.endTime }})
                        </template>
                        <template #item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title>{{ item.name }}</v-list-item-title>
                            <v-list-item-subtitle>
                              {{ item.startTime }} - {{ item.endTime }} |
                              {{ item.hasBreak ? `Descanso: ${item.breakDuration} min` : 'Sin descanso' }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </v-col>

                    <v-col cols="12">
                      <v-divider class="mb-3"></v-divider>
                      <v-card-subtitle>Agenda</v-card-subtitle>
                      <v-row v-for="(slot, index) in scheduleSlots" :key="index" align="center" class="mb-2">
                        <v-col cols="12" sm="5">
                          <v-text-field v-model="slot.startTime" label="Hora de Inicio" type="time" outlined
                            dense></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="5">
                          <v-text-field v-model="slot.endTime" label="Hora de Fin" type="time" outlined
                            dense></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2">
                          <v-btn icon color="error" @click="removeTimeSlot(index)" v-if="scheduleSlots.length > 1">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-col>
                        <v-col cols="12" v-if="scheduleSlots.length === 1 || index === scheduleSlots.length - 1">
                          <v-checkbox v-model="slot.hasBreak" label="Incluir descanso" hide-details></v-checkbox>
                          <v-text-field v-if="slot.hasBreak" v-model.number="slot.breakDuration"
                            label="Minutos de descanso" type="number" outlined dense class="mt-2"></v-text-field>
                        </v-col>
                      </v-row>

                      <v-btn color="primary" @click="addTimeSlot" class="mt-2">
                        <v-icon left>mdi-plus</v-icon>
                        Agregar Horario
                      </v-btn>
                    </v-col>

                    <v-col cols="12" sm="6">
                      <v-menu v-model="startDateMenu" :close-on-content-click="false" transition="scale-transition"
                        offset-y min-width="290px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="scheduleTemplate.startDate" label="Fecha de Inicio"
                            prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined
                            dense></v-text-field>
                        </template>
                        <v-date-picker v-model="scheduleTemplate.startDate"
                          @input="startDateMenu = false"></v-date-picker>
                      </v-menu>
                    </v-col>

                    <v-col cols="12" sm="6">
                      <v-menu v-model="endDateMenu" :close-on-content-click="false" transition="scale-transition"
                        offset-y min-width="290px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="scheduleTemplate.endDate" label="Fecha de Fin"
                            prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined
                            dense></v-text-field>
                        </template>
                        <v-date-picker v-model="scheduleTemplate.endDate"
                          @input="endDateMenu = false"></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="error" text @click="cancelScheduleEdit">
                    Cancelar
                  </v-btn>
                  <v-btn color="success" :loading="loading.schedule" @click="applySchedule">
                    Aplicar Horario
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text v-else>
          <v-alert type="info" text>
            Seleccione un empleado de la pestaña Empleados para editar su horario.
          </v-alert>
        </v-card-text>
      </v-card>
    </v-tab-item>

    <!-- Reports Tab -->
    <v-tab-item>
      <v-divider />

      <v-card flat>
        <v-card-title>Gestión de Asistencias</v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" sm="4">
              <v-select @change="generateReport" v-model="reportFilter.dateRange" :items="itemsDateRange" label="Rango de Fechas" outlined dense></v-select>
            </v-col>

            <v-col cols="12" sm="4" v-if="reportFilter.dateRange === 'custom'">
              <v-menu v-model="startReportDateMenu" :close-on-content-click="false" transition="scale-transition"
                offset-y min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="reportFilter.startDate" label="Desde" prepend-icon="mdi-calendar" readonly
                    v-bind="attrs" v-on="on" outlined dense></v-text-field>
                </template>
                <v-date-picker v-model="reportFilter.startDate"
                  @input="startReportDateMenu = false"></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="4" v-if="reportFilter.dateRange === 'custom'">
              <v-menu v-model="endReportDateMenu" :close-on-content-click="false" transition="scale-transition"
                offset-y min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="reportFilter.endDate" label="Hasta" prepend-icon="mdi-calendar" readonly
                    v-bind="attrs" v-on="on" outlined dense></v-text-field>
                </template>
                <v-date-picker v-model="reportFilter.endDate" @input="endReportDateMenu = false"></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="4">
              <v-select v-model="reportFilter.userFilter" :items="[
                { text: 'Todos los Empleados', value: 'all' },
                { text: 'Empleados Activos', value: 'active' },
                { text: 'Empleados Seleccionados', value: 'selected' }
              ]" label="Empleados" outlined dense></v-select>
            </v-col>

            <v-col cols="12">
              <v-btn color="primary" :loading="loading.reports" @click="generateReport">
                <v-icon left>mdi-file-chart</v-icon>
                Generar Reporte
              </v-btn>
              <v-btn color="success" class="ml-2" @click="openManualCheckInDialog">
                <v-icon left>mdi-login</v-icon>
                Registrar Asistencia Manual
              </v-btn>
            </v-col>
          </v-row>

          <v-data-table v-if="reportData.length > 0" :headers="getReportHeaders()" :items="reportData"
            class="elevation-1 mt-4" :item-class="getReportRowClass">
            <template v-slot:item.adherence="{ item }">
              <div :class="'item-adherence '+item.adherenceClass">
                {{ item.adherence }}
                <div v-if="item.adherence === 'Sin Programación'" class="adherence-details">
                  <v-btn x-small color="warning" @click="handleUnscheduledEntry(item)" v-if="!item.isResolved">
                    Asignar Turno
                  </v-btn>
                </div>
              </div>
            </template>
            <template v-slot:item.lunchDuration="{ item }">
              <div class="d-flex align-center">
                <span>{{ item.lunchDuration }}</span>
                <span v-if="item.excessBreakTime > 0" class="ml-1 error--text">
                  (+{{ item.excessBreakTime }} min)
                </span>
              </div>
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="d-flex">
                <v-btn icon small class="mr-2" v-if="!item.checkOutTime && item.recordType === 'attendance'" 
                       @click="manualCheckout(item)" color="success">
                  <v-icon small>mdi-logout</v-icon>
                </v-btn>
                <v-btn icon small v-if="item.recordType === 'no-show'" @click="performManualCheckIn(item)" color="primary">
                  <v-icon small>mdi-login</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>

          <v-card-actions v-if="reportData.length > 0">
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="downloadReport('csv')" class="mr-2">
              <v-icon left>mdi-download</v-icon>
              Descargar CSV
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
      
      <!-- Manual Check-in Dialog -->
      <v-dialog v-model="manualCheckInDialog" max-width="500px">
        <v-card>
          <v-card-title>
            Registro Manual de Asistencia
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-select v-model="manualCheckIn.userId" :items="users" item-text="name" item-value="id"
                    label="Empleado" outlined required></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-menu v-model="manualCheckInDateMenu" :close-on-content-click="false" transition="scale-transition"
                    offset-y min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="manualCheckIn.date" label="Fecha" prepend-icon="mdi-calendar" readonly
                        v-bind="attrs" v-on="on" outlined></v-text-field>
                    </template>
                    <v-date-picker v-model="manualCheckIn.date" 
                      @input="manualCheckInDateMenu = false"
                      :max="(new Date()).toISOString().substr(0, 10)"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="manualCheckIn.time" label="Hora" type="time" outlined required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select v-model="manualCheckIn.type" :items="[
                    { text: 'Entrada', value: 'checkIn' },
                    { text: 'Salida', value: 'checkOut' },
                    { text: 'Inicio de Descanso', value: 'lunchStart' },
                    { text: 'Fin de Descanso', value: 'lunchEnd' }
                  ]" label="Tipo de Registro" outlined required></v-select>
                </v-col>
                <v-col cols="12">
                  <v-textarea v-model="manualCheckIn.note" label="Nota (requerida)" outlined rows="3" required></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeManualCheckInDialog">Cancelar</v-btn>
            <v-btn color="blue darken-1" text @click="submitManualCheckIn" :loading="loading.manualCheckIn">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Unscheduled Entry Dialog -->
      <v-dialog v-model="unscheduledEntryDialog" max-width="500px">
        <v-card>
          <v-card-title>
            Asignar Turno a Entrada Sin Programación
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-alert type="warning" text dense>
                    Este empleado tiene un registro de entrada sin turno programado. Por favor, asigne un turno.
                  </v-alert>
                </v-col>
                <v-col cols="12">
                  <div class="subtitle-1 font-weight-bold">Empleado: {{ unscheduledEntry.userName }}</div>
                  <div class="subtitle-2">Fecha: {{ unscheduledEntry.date }}</div>
                  <div class="subtitle-2">Hora de entrada: {{ unscheduledEntry.checkIn }}</div>
                </v-col>
                <v-col cols="12">
                  <v-radio-group v-model="unscheduledEntry.action" row>
                    <v-radio label="Usar Turno Existente" value="useShift"></v-radio>
                    <v-radio label="Crear Nuevo Turno" value="createSchedule"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" v-if="unscheduledEntry.action === 'useShift'">
                  <v-select v-model="unscheduledEntry.shiftId" :items="shifts" item-text="name" item-value="id"
                    label="Seleccionar Turno" outlined>
                    <template #selection="{ item }">
                      {{ item.name }} ({{ item.startTime }} - {{ item.endTime }})
                    </template>
                    <template #item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ item.startTime }} - {{ item.endTime }} |
                          {{ item.hasBreak ? `Descanso: ${item.breakDuration} min` : 'Sin descanso' }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" v-if="unscheduledEntry.action === 'createSchedule'">
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field v-model="unscheduledEntry.startTime" label="Hora de Inicio" type="time" outlined
                        required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field v-model="unscheduledEntry.endTime" label="Hora de Fin" type="time" outlined
                        required></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-switch v-model="unscheduledEntry.hasBreak" color="primary" label="Incluye Descanso"></v-switch>
                    </v-col>
                    <v-col cols="12" v-if="unscheduledEntry.hasBreak">
                      <v-text-field v-model.number="unscheduledEntry.breakDuration" 
                                   label="Duración del Descanso (minutos)"
                                   type="number" outlined required></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="unscheduledEntryDialog = false">Cancelar</v-btn>
            <v-btn color="blue darken-1" text @click="assignScheduleToEntry" 
                  :loading="loading.assignSchedule">Asignar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-tab-item>

    <!-- Payroll Tab -->
    <v-tab-item>
      <v-divider />

      <v-card flat>
        <v-card-title>Gestión de Remuneraciones</v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" sm="4">
              <v-select v-model="payrollFilter.period" :items="itemsPayrollPeriod" label="Período" outlined dense></v-select>
            </v-col>

            <v-col cols="12" sm="4" v-if="payrollFilter.period === 'custom'">
              <v-menu v-model="startPayrollDateMenu" :close-on-content-click="false" transition="scale-transition"
                offset-y min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="payrollFilter.startDate" label="Desde" prepend-icon="mdi-calendar" readonly
                    v-bind="attrs" v-on="on" outlined dense></v-text-field>
                </template>
                <v-date-picker v-model="payrollFilter.startDate"
                  @input="startPayrollDateMenu = false"></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="4" v-if="payrollFilter.period === 'custom'">
              <v-menu v-model="endPayrollDateMenu" :close-on-content-click="false" transition="scale-transition"
                offset-y min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="payrollFilter.endDate" label="Hasta" prepend-icon="mdi-calendar" readonly
                    v-bind="attrs" v-on="on" outlined dense></v-text-field>
                </template>
                <v-date-picker v-model="payrollFilter.endDate" @input="endPayrollDateMenu = false"></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="4">
              <v-select v-model="payrollFilter.userFilter" :items="[
                { text: 'Todos los Empleados', value: 'all' },
                { text: 'Empleados Activos', value: 'active' },
                { text: 'Empleados Seleccionados', value: 'selected' }
              ]" label="Empleados" outlined dense></v-select>
            </v-col>

            <v-col cols="12">
              <v-btn color="primary" :loading="loading.payroll" @click="generatePayrollReport">
                <v-icon left>mdi-cash-multiple</v-icon>
                Generar Reporte
              </v-btn>
            </v-col>
          </v-row>

          <v-data-table v-if="payrollData.length > 0" :headers="getPayrollHeaders()" :items="payrollData"
            class="elevation-1 mt-4">
            <template #item.totalHours="{ item }">
              <span class="font-weight-bold">{{ item.totalHours }}</span>
            </template>
            <template #item.extraHours="{ item }">
              <span :class="item.extraHours > 0 ? 'success--text' : ''">{{ item.extraHours }}</span>
            </template>
            <template #item.payAmount="{ item }">
              <span class="font-weight-bold">{{ item.payAmount }}</span>
            </template>
          </v-data-table>

          <v-card-actions v-if="payrollData.length > 0">
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="downloadPayroll('csv')" class="mr-2">
              <v-icon left>mdi-download</v-icon>
              Descargar CSV
            </v-btn>
            <v-btn color="success" @click="downloadPayroll('pdf')">
              <v-icon left>mdi-file-pdf</v-icon>
              Descargar PDF
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-tab-item>
    
    <!-- Alerts Tab -->
    <v-tab-item>
      <v-divider />

      <v-card flat>
        <v-card-title>
          Alertas de Personal
          <v-spacer></v-spacer>
          <v-select v-model="alertFilter.type" :items="[
            { text: 'Todos los Tipos', value: 'all' },
            { text: 'Entradas Tardías', value: 'late' },
            { text: 'Salidas Tempranas', value: 'early' },
            { text: 'Salidas Faltantes', value: 'missing' },
            { text: 'Descansos Largos', value: 'longBreak' }
          ]" label="Tipo" outlined dense class="mx-2" style="max-width: 200px" hide-details></v-select>
          <v-select v-model="alertFilter.period" :items="[
            { text: 'Hoy', value: 'today' },
            { text: 'Esta Semana', value: 'week' },
            { text: 'Este Mes', value: 'month' }
          ]" label="Período" outlined dense style="max-width: 200px" hide-details></v-select>
        </v-card-title>
        <v-divider />

        <v-card-text>
          <v-progress-linear v-if="loading.alerts" indeterminate color="primary"></v-progress-linear>

          <div v-else>
            <v-alert v-if="filteredAlerts.length === 0" type="info" text>
              No se encontraron alertas para los filtros seleccionados.
            </v-alert>

            <v-alert text v-for="alert in filteredAlerts" :key="alert.id" :class="{
              'mb-4': true,
              'resolved': resolvedAlerts.has(alert.id)
            }" :color="getAlertColor(alert.severity)">
              <v-card-title class="py-2">

                <div class="d-flex align-center">
                  <span>{{ getTranslatedAlertType(alert.type) }}</span>
                  <v-chip small class="ml-2" label outlined>
                    {{ getAlertSeverityText(alert.severity) }}
                  </v-chip>
                </div>
                <v-spacer></v-spacer>
                <span class="caption grey--text">{{ alert.time }}</span>
              </v-card-title>

              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <div class="subtitle-1 font-weight-bold mb-2">{{ alert.user }}</div>
                    <div class="body-2">{{ alert.message }}</div>

                    <div class="mt-3" v-if="alert.minutes">
                      <v-chip :color="getMetricColor(alert.minutes)" outlined small>
                        {{
                          alert.type === 'Late Check-in' ? `${alert.minutes} minutos tarde` :
                            alert.type === 'Early Check-out' ? `${alert.minutes} minutos temprano` :
                              alert.type === 'Long Break' ? `${alert.minutes} minutos de descanso` :
                                `${alert.minutes} minutos`
                        }}
                      </v-chip>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-actions v-if="alert.type === 'Missing Check-out'">
                <v-spacer></v-spacer>
                <v-btn color="error" @click="forceCheckout(alert)" text>
                  <v-icon left>mdi-logout</v-icon>
                  Forzar Salida
                </v-btn>
              </v-card-actions>
            </v-alert>
          </div>
        </v-card-text>
      </v-card>
    </v-tab-item>
  </v-tabs>


</v-card>
</v-container>
</template>

<script>
import { getFirestore, collection, query, where, getDocs, doc, updateDoc, deleteDoc, orderBy, Timestamp, addDoc, serverTimestamp, getDoc, writeBatch, setDoc } from 'firebase/firestore';
import moment from 'moment';
import AdminScheduleCalendar from './AdminScheduleCalendar.vue';

const db = getFirestore();

export default {
name: 'WorkforceAdminPanel',
components: {
AdminScheduleCalendar
},
data() {
return {
  itemsDateRange: [
                { text: 'Hoy', value: 'today' },
                { text: 'Ayer', value: 'yesterday' },
                { text: 'Esta Semana', value: 'thisWeek' },
                { text: 'Semana Pasada', value: 'lastWeek' },
                { text: 'Este Mes', value: 'thisMonth' },
                { text: 'Rango Personalizado', value: 'custom' }
              ],
  itemsPayrollPeriod: [
                { text: 'Esta Semana', value: 'thisWeek' },
                { text: 'Semana Pasada', value: 'lastWeek' },
                { text: 'Este Mes', value: 'thisMonth' },
                { text: 'Mes Pasado', value: 'lastMonth' },
                { text: 'Rango Personalizado', value: 'custom' }
              ],
  activeTab: 0,
  userSearch: '',
  users: [],
  loading: {
    users: false,
    schedules: false,
    reports: false,
    alerts: false,
    schedule: false,
    userSchedule: false,
    shifts: false,
    shiftSave: false,
    shiftDelete: false,
    manualCheckIn: false,
    assignSchedule: false,
    payroll: false
  },
  selectedUser: null,
  weekDays: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
  scheduleTemplate: {
    days: [],
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().add(30, 'days').format('YYYY-MM-DD')
  },
  scheduleSlots: [{
    startTime: '09:00',
    endTime: '17:00',
    hasBreak: false,
    breakDuration: 60
  }],
  selectedShift: null,
  reportFilter: {
    dateRange: 'thisWeek',
    startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    type: 'adherence',
    users: [],
    showAdherence: true
  },
  reportData: [],
  alertFilter: {
    type: 'all',
    period: 'today'
  },
  alerts: [],
  selectedUserSchedule: [],
  resolvedAlerts: new Set(),
  startReportDateMenu: false,
  endReportDateMenu: false,
  startDateMenu: false,
  endDateMenu: false,
  startReportDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
  endReportDate: moment().format('YYYY-MM-DD'),
  shifts: [],
  shiftDialog: false,
  editedShift: {
    name: '',
    startTime: '09:00',
    endTime: '17:00',
    hasBreak: false,
    breakDuration: 60,
    description: ''
  },
  deleteShiftDialog: false,
  shiftToDelete: null,
  // New properties for manual check-in
  manualCheckInDialog: false,
  manualCheckInDateMenu: false,
  manualCheckIn: {
    userId: null,
    date: moment().format('YYYY-MM-DD'),
    time: moment().format('HH:mm'),
    type: 'checkIn',
    note: ''
  },
  // For unscheduled entries
  unscheduledEntryDialog: false,
  unscheduledEntry: {
    id: null,
    userId: null,
    userName: '',
    date: '',
    checkIn: '',
    action: 'useShift',
    shiftId: null,
    startTime: '09:00',
    endTime: '17:00',
    hasBreak: false,
    breakDuration: 60
  },
  // For payroll tab
  payrollFilter: {
    period: 'thisMonth',
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    userFilter: 'all',
    users: []
  },
  payrollData: [],
  startPayrollDateMenu: false,
  endPayrollDateMenu: false
}
},
computed: {
filteredUsers() {
  if (!this.userSearch) return this.users;

  const search = this.userSearch.toLowerCase();
  return this.users.filter(user =>
    user.name.toLowerCase().includes(search) ||
    user.status.toLowerCase().includes(search)
  );
},
filteredAlerts() {
  let filtered = this.alerts.filter(alert => !this.resolvedAlerts.has(alert.id));

  if (this.alertFilter.type !== 'all') {
    const typeMap = {
      'late': 'Entrada Tardía',
      'early': 'Salida Temprana',
      'missing': 'Salida Faltante',
      'longBreak': 'Descanso Largo',
      'consistent': 'Llegadas Tardías Consistentes'
    };

    filtered = filtered.filter(alert =>
      alert.type === typeMap[this.alertFilter.type]
    );
  }

  if (this.alertFilter.period !== 'today') {
    const now = moment();
    const periodStart = this.alertFilter.period === 'week'
      ? moment().subtract(7, 'days')
      : moment().subtract(30, 'days');

    filtered = filtered.filter(alert => {
      const alertTime = moment(alert.timestamp);
      return alertTime.isAfter(periodStart) && alertTime.isBefore(now);
    });
  }

  return filtered;
},
userMap() {
  const map = {};
  this.users.forEach(user => {
    map[user.id] = user.name;
  });
  return map;
}
},
methods: {
getTranslatedAlertType(type) {
  const translations = {
    'Late Arrival': 'Llegada Tardía',
    'Late Check-in': 'Entrada Tardía',
    'Missed Shift': 'Turno Perdido',
    'Early Departure': 'Salida Temprana',
    'Early Check-out': 'Salida Temprana',
    'Missing Check-out': 'Salida Faltante',
    'Long Break': 'Descanso Largo',
    'Consistent Late Arrivals': 'Llegadas Tardías Consistentes'
  };
  return translations[type] || type;
},

async fetchUsers() {
  this.loading.users = true;
  try {
    const usersQuery = query(
      collection(db, 'users'),
      where('type', '==', 'entrenador'),
      where('disabled', '==', false)
    );

    const usersSnapshot = await getDocs(usersQuery);

    const users = [];

    // Fetch all attendance records for today to get current status
    const todayStart = moment().startOf('day').toDate();
    const todayEnd = moment().endOf('day').toDate();

    const attendanceQuery = query(
      collection(db, 'attendance'),
      where('date', '>=', todayStart),
      where('date', '<=', todayEnd),
      orderBy('date', 'desc')
    );

    const attendanceSnapshot = await getDocs(attendanceQuery);

    // Group attendance by userId
    const attendanceByUser = {};
    attendanceSnapshot.docs.forEach(doc => {
      const data = doc.data();
      if (!attendanceByUser[data.userId]) {
        attendanceByUser[data.userId] = [];
      }
      attendanceByUser[data.userId].push(data);
    });

    // Get today's schedules to check if users are scheduled to work today
    const todaySchedulesQuery = query(
      collection(db, 'schedulesConfig'),
      where('date', '>=', todayStart),
      where('date', '<=', todayEnd)
    );

    const schedulesSnapshot = await getDocs(todaySchedulesQuery);

    // Create a map of users who have schedules today
    const userScheduledToday = {};
    schedulesSnapshot.docs.forEach(doc => {
      const scheduleData = doc.data();
      userScheduledToday[scheduleData.userId] = true;
    });

    // Process each user
    for (const userDoc of usersSnapshot.docs) {
      const userData = userDoc.data();

      // Use displayName from userData as the primary name source
      const userName = userData.displayName || userData.name || 'Unknown User';

      const userId = userDoc.id;
      const userAttendance = attendanceByUser[userId] || [];
      let status = 'No ha Registrado Entrada';

      // Check if user is scheduled to work today
      if (!userAttendance.length) {
        status = userScheduledToday[userId] ?
          'Debe Trabajar Hoy' :
          'No Programado Hoy';
      } else {
        // Sort attendance records by date (newest first)
        userAttendance.sort((a, b) => {
          return b.date.toDate() - a.date.toDate();
        });

        const latestRecord = userAttendance[0];

        // Determine current status from latest record
        if (!latestRecord.checkOutTime) {
          status = latestRecord.lunchStartTime && !latestRecord.lunchEndTime ? 'En Descanso' : 'Trabajando';
        } else {
          // User has checked out but may have done multiple shifts
          const completedShifts = userAttendance.filter(a => a.checkOutTime).length;
          status = completedShifts > 1 ? `Completó ${completedShifts} Turnos` : 'Registró Salida';
        }
      }

      users.push({
        id: userId,
        name: userName,
        status
      });
    }

    this.users = users;
  } catch (error) {
    console.error('Error fetching users:', error);
    this.$emit('error', 'Error al cargar usuarios');
  } finally {
    this.loading.users = false;
  }
},

viewUserSchedule(user) {
  this.selectedUser = user;
  this.activeTab = 2; // Updated to reflect new tab position
  this.fetchUserSchedule(user.id);
},

editUserSchedule(user) {
  this.selectedUser = user;
  this.activeTab = 2; // Updated to reflect new tab position

  this.scheduleSlots = [{
    startTime: '09:00',
    endTime: '17:00',
    hasBreak: false,
    breakDuration: 60
  }];

  this.scheduleTemplate = {
    days: [],
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().add(30, 'days').format('YYYY-MM-DD')
  };

  this.fetchUserSchedule(user.id);
},

async fetchUserSchedule(userId) {
  this.loading.userSchedule = true;
  try {
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();

    const scheduleQuery = query(
      collection(db, 'schedulesConfig'),
      where('userId', '==', userId),
      where('month', '==', currentMonth),
      where('year', '==', currentYear)
    );

    const scheduleSnapshot = await getDocs(scheduleQuery);
    this.selectedUserSchedule = scheduleSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error('Error fetching user schedule:', error);
    this.$emit('error', 'Error al cargar el horario del usuario');
  } finally {
    this.loading.userSchedule = false;
  }
},

addTimeSlot() {
  this.scheduleSlots.push({
    startTime: '09:00',
    endTime: '17:00',
    hasBreak: false,
    breakDuration: 60
  });
},

removeTimeSlot(index) {
  if (this.scheduleSlots.length > 1) {
    this.scheduleSlots.splice(index, 1);
  }
},

async applySchedule() {
  if (!this.selectedUser || !this.scheduleTemplate.days.length) return;

  this.loading.schedule = true;
  const db = getFirestore();
  const batch = writeBatch(db);

  try {
    const startDate = moment(this.scheduleTemplate.startDate);
    const endDate = moment(this.scheduleTemplate.endDate);

    // Delete existing schedules in the date range
    const existingSchedulesQuery = query(
      collection(db, 'schedulesConfig'),
      where('userId', '==', this.selectedUser.id),
      where('date', '>=', startDate.toDate()),
      where('date', '<=', endDate.toDate())
    );

    const existingSchedules = await getDocs(existingSchedulesQuery);
    existingSchedules.docs.forEach(doc => {
      batch.delete(doc.ref);
    });

    // Create new schedules
    const currentDate = moment(startDate);
    while (currentDate.isSameOrBefore(endDate)) {
      const dayOfWeek = currentDate.day();

      if (this.scheduleTemplate.days.includes(dayOfWeek)) {
        const scheduleRef = doc(collection(db, 'schedulesConfig'));
        const scheduleDate = currentDate.toDate();

        batch.set(scheduleRef, {
          userId: this.selectedUser.id,
          date: scheduleDate,
          timeSlots: this.scheduleSlots.map(slot => ({
            startTime: slot.startTime,
            endTime: slot.endTime,
            hasBreak: slot.hasBreak,
            breakDuration: slot.breakDuration || 60
          })),
          createdAt: serverTimestamp()
        });
      }
      currentDate.add(1, 'days');
    }

    await batch.commit();

    // Refresh the calendar
    if (this.$refs.adminCalendar) {
      this.$refs.adminCalendar.refresh();
    }

    this.$notify({
      type: 'success',
      title: 'Horario Actualizado',
      text: 'El horario ha sido actualizado exitosamente'
    });

    // Reset the form after successful apply
    this.resetScheduleForm();

  } catch (error) {
    console.error('Error applying schedule:', error);
    this.$notify({
      type: 'error',
      title: 'Error',
      text: 'Hubo un error al actualizar el horario'
    });
  } finally {
    this.loading.schedule = false;
  }
},

resetScheduleForm() {
  // Reset days selection
  this.scheduleTemplate = {
    days: [],
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().add(30, 'days').format('YYYY-MM-DD')
  };

  // Reset time slots
  this.scheduleSlots = [{
    startTime: '09:00',
    endTime: '17:00',
    hasBreak: false,
    breakDuration: 60
  }];

  // Reset shift selection
  this.selectedShift = null;
  
  // Add a brief highlight animation to the form to indicate it's been reset
  this.$nextTick(() => {
    const formElement = this.$el.querySelector('.v-card-text');
    if (formElement) {
      formElement.classList.add('form-reset-flash');
      setTimeout(() => {
        formElement.classList.remove('form-reset-flash');
      }, 1000);
    }
  });
},

cancelScheduleEdit() {
  this.resetScheduleForm();
  this.selectedUser = null;
},

getStatusColor(status) {
  if (status === 'Trabajando') return 'success';
  if (status === 'En Descanso') return 'warning';
  if (status === 'No ha Registrado Entrada') return 'grey';
  if (status === 'Debe Trabajar Hoy') return 'error';
  if (status === 'No Programado Hoy') return 'grey darken-1';
  if (status === 'Registró Salida' || status.includes('Completó')) return 'info';
  return '';
},

openShiftDialog(shift) {
  this.editedShift = shift ? { ...shift } : {
    name: '',
    startTime: '09:00',
    endTime: '17:00',
    hasBreak: false,
    breakDuration: 60,
    description: ''
  };
  this.shiftDialog = true;
},

closeShiftDialog() {
  this.shiftDialog = false;
  this.editedShift = {
    name: '',
    startTime: '09:00',
    endTime: '17:00',
    hasBreak: false,
    breakDuration: 60,
    description: ''
  };
},

async saveShift() {
  this.loading.shiftSave = true;
  try {
    // Validate the shift
    if (!this.editedShift.name || !this.editedShift.startTime || !this.editedShift.endTime) {
      this.$emit('error', 'Por favor complete todos los campos requeridos');
      return;
    }

    // Make sure breakDuration is a number
    if (this.editedShift.hasBreak) {
      this.editedShift.breakDuration = parseInt(this.editedShift.breakDuration) || 0;
    } else {
      this.editedShift.breakDuration = 0;
    }

    if (this.editedShift.id) {
      // Update existing shift
      const shiftRef = doc(db, 'shifts', this.editedShift.id);
      await updateDoc(shiftRef, {
        name: this.editedShift.name,
        startTime: this.editedShift.startTime,
        endTime: this.editedShift.endTime,
        hasBreak: this.editedShift.hasBreak,
        breakDuration: this.editedShift.breakDuration,
        description: this.editedShift.description,
        updatedAt: serverTimestamp()
      });

      this.$notify({
        type: 'success',
        title: 'Turno Actualizado',
        text: 'El turno ha sido actualizado exitosamente'
      });
    } else {
      // Add new shift
      await addDoc(collection(db, 'shifts'), {
        name: this.editedShift.name,
        startTime: this.editedShift.startTime,
        endTime: this.editedShift.endTime,
        hasBreak: this.editedShift.hasBreak,
        breakDuration: this.editedShift.breakDuration,
        description: this.editedShift.description,
        createdAt: serverTimestamp()
      });

      this.$notify({
        type: 'success',
        title: 'Turno Creado',
        text: 'El turno ha sido creado exitosamente'
      });
    }

    // Refresh shifts
    this.fetchShifts();
    this.closeShiftDialog();
  } catch (error) {
    console.error('Error saving shift:', error);
    this.$emit('error', 'Hubo un error al guardar el turno');
  } finally {
    this.loading.shiftSave = false;
  }
},

confirmDeleteShift(shift) {
  this.shiftToDelete = shift;
  this.deleteShiftDialog = true;
},

async deleteShift() {
  if (!this.shiftToDelete) return;

  this.loading.shiftDelete = true;
  try {
    await deleteDoc(doc(db, 'shifts', this.shiftToDelete.id));

    this.$notify({
      type: 'success',
      title: 'Turno Eliminado',
      text: 'El turno ha sido eliminado exitosamente'
    });

    // Refresh shifts
    this.fetchShifts();
    this.deleteShiftDialog = false;
    this.shiftToDelete = null;
  } catch (error) {
    console.error('Error deleting shift:', error);
    this.$emit('error', 'Hubo un error al eliminar el turno');
  } finally {
    this.loading.shiftDelete = false;
  }
},

calculateDuration(startTime, endTime) {
  const start = moment(startTime, 'HH:mm');
  const end = moment(endTime, 'HH:mm');

  // Handle overnight shifts
  let duration = moment.duration(end.diff(start));
  if (duration.asHours() < 0) {
    duration = moment.duration(end.add(1, 'day').diff(start));
  }

  return duration.asHours().toFixed(1);
},

applyShiftToSchedule() {
  if (!this.selectedShift) return;

  // Find the selected shift
  const shift = this.shifts.find(s => s.id === this.selectedShift);
  if (!shift) return;

  // Apply it to the current schedule slot
  this.scheduleSlots = [{
    startTime: shift.startTime,
    endTime: shift.endTime,
    hasBreak: shift.hasBreak,
    breakDuration: shift.breakDuration
  }];
},

async fetchShifts() {
  this.loading.shifts = true;
  try {
    const shiftsQuery = query(
      collection(db, 'shifts'),
      orderBy('name')
    );

    const shiftsSnapshot = await getDocs(shiftsQuery);
    this.shifts = shiftsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error('Error fetching shifts:', error);
    this.$emit('error', 'Failed to load shifts');
  } finally {
    this.loading.shifts = false;
  }
},

async fetchAlerts() {
      // Don't fetch alerts if users haven't been loaded yet
      if (this.users.length === 0) {
        await this.fetchUsers();
      }

      this.loading.alerts = true;
      try {
        const alerts = [];
        let periodStart;

        // Determine the date range based on the period filter
        switch (this.alertFilter.period) {
          case 'today':
            periodStart = moment().startOf('day').toDate();
            break;
          case 'week':
            periodStart = moment().subtract(7, 'days').startOf('day').toDate();
            break;
          case 'month':
            periodStart = moment().subtract(30, 'days').startOf('day').toDate();
            break;
          default:
            periodStart = moment().startOf('day').toDate();
        }

        console.log('Fetching alerts from', periodStart, 'to', moment().endOf('day').toDate());

        // Get all schedules for the selected period
        const schedulesQuery = query(
          collection(db, 'schedulesConfig'),
          where('date', '>=', periodStart),
          where('date', '<=', moment().endOf('day').toDate())
        );

        const schedulesSnapshot = await getDocs(schedulesQuery);
        const schedules = schedulesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        // Organize schedules by date and user
        const schedulesByDateAndUser = {};

        schedules.forEach(schedule => {
          const scheduleDate = schedule.date.toDate();
          const dateKey = moment(scheduleDate).format('YYYY-MM-DD');
          const userId = schedule.userId;

          if (!schedulesByDateAndUser[dateKey]) {
            schedulesByDateAndUser[dateKey] = {};
          }

          schedulesByDateAndUser[dateKey][userId] = schedule;
        });

        // Get attendance records for the selected period
        const attendanceQuery = query(
          collection(db, 'attendance'),
          where('date', '>=', periodStart),
          where('date', '<=', moment().endOf('day').toDate())
        );

        const attendanceSnapshot = await getDocs(attendanceQuery);

        // Group attendance by date and user
        const attendanceByDateAndUser = {};

        attendanceSnapshot.docs.forEach(doc => {
          const data = doc.data();
          if (!data.date) return;

          const attendanceDate = data.date.toDate();
          const dateKey = moment(attendanceDate).format('YYYY-MM-DD');
          const userId = data.userId;

          if (!attendanceByDateAndUser[dateKey]) {
            attendanceByDateAndUser[dateKey] = {};
          }

          if (!attendanceByDateAndUser[dateKey][userId]) {
            attendanceByDateAndUser[dateKey][userId] = [];
          }

          attendanceByDateAndUser[dateKey][userId].push({
            id: doc.id,
            ...data
          });
        });

        // Process each day
        const now = new Date();
        const today = moment(now).format('YYYY-MM-DD');

        // Track users with multiple late arrivals
        const lateArrivalsCount = {};

        // First check each scheduled shift for alerts
        for (const dateKey in schedulesByDateAndUser) {
          for (const userId in schedulesByDateAndUser[dateKey]) {
            const schedule = schedulesByDateAndUser[dateKey][userId];
            const timeSlots = schedule.timeSlots || [{
              startTime: schedule.startTime || '09:00',
              endTime: schedule.endTime || '17:00'
            }];

            const attendanceRecords = attendanceByDateAndUser[dateKey]?.[userId] || [];
            const userName = this.userMap[userId] || schedule.userName || 'Unknown User';

            // If this is a past or current day
            const scheduleDate = schedule.date.toDate();
            const isPastOrCurrentDay = scheduleDate <= now;

            if (isPastOrCurrentDay) {
              // First identify missing attendance
              if (attendanceRecords.length === 0) {
                // No attendance records for a scheduled day that has passed
                if (dateKey !== today) { // Only report as missed for past days
                  timeSlots.forEach((slot, index) => {
                    alerts.push({
                      id: `missed-${userId}-${dateKey}-${index}`,
                      type: 'Turno Perdido',
                      severity: 'error',
                      user: userName,
                      userId,
                      time: `${moment(scheduleDate).format('MMM D')} - ${slot.startTime}`,
                      timestamp: scheduleDate,
                      message: `${userName} tenía programado trabajar el ${moment(scheduleDate).format('MMM D')} a las ${slot.startTime} pero no registró entrada.`,
                      actionRequired: true
                    });
                  });
                } else if (dateKey === today) {
                  // For today, only report as missed if the start time has passed
                  timeSlots.forEach((slot, index) => {
                    const startTimeParts = slot.startTime.split(':');
                    const scheduledStart = new Date(scheduleDate);
                    scheduledStart.setHours(parseInt(startTimeParts[0]), parseInt(startTimeParts[1]), 0, 0);

                    if (now > scheduledStart && now.getTime() - scheduledStart.getTime() > 60 * 60 * 1000) { // 1 hour grace period
                      alerts.push({
                        id: `missed-today-${userId}-${index}`,
                        type: 'Late Arrival',
                        severity: 'error',
                        user: userName,
                        userId,
                        time: `Hoy - ${slot.startTime}`,
                        timestamp: now,
                        message: `${userName} tenía programado empezar a las ${slot.startTime} hoy pero no registró entrada y está más de 1 hora tarde.`,
                        actionRequired: true
                      });
                    }
                  });
                }
              } else {
                // Check for partial attendance (missing some shifts)
                if (attendanceRecords.length < timeSlots.length && dateKey !== today) {
                  alerts.push({
                    id: `partial-${userId}-${dateKey}`,
                    type: 'Incomplete Shifts',
                    severity: 'warning',
                    user: userName,
                    userId,
                    time: moment(scheduleDate).format('MMM D'),
                    timestamp: scheduleDate,
                    message: `${userName} completó solo ${attendanceRecords.length} de ${timeSlots.length} turnos programados el ${moment(scheduleDate).format('MMM D')}.`,
                    actionRequired: false
                  });
                }
              }
            }

            // Now check each attendance record for specific issues
            attendanceRecords.forEach((record, recordIndex) => {
              // 1. Check for late check-ins
              if (record.checkInTime && record.slotIndex !== undefined && timeSlots[record.slotIndex]) {
                const slot = timeSlots[record.slotIndex];
                const startTimeParts = slot.startTime.split(':');
                const scheduledStart = new Date(scheduleDate);
                scheduledStart.setHours(parseInt(startTimeParts[0]), parseInt(startTimeParts[1]), 0, 0);

                const checkInTime = record.checkInTime.toDate();
                const minutesLate = Math.round((checkInTime - scheduledStart) / (1000 * 60));

                if (minutesLate > 15) { // Over 15 minutes late
                  // Track late arrivals for this user
                  if (!lateArrivalsCount[userId]) {
                    lateArrivalsCount[userId] = {
                      count: 0,
                      userName
                    };
                  }
                  lateArrivalsCount[userId].count++;

                  alerts.push({
                    id: `late-${record.id}`,
                    type: 'Entrada Tardía',
                    severity: minutesLate > 60 ? 'error' : 'warning',
                    user: userName,
                    userId,
                    time: moment(checkInTime).format('MMM D, h:mm A'),
                    timestamp: checkInTime,
                    message: `${userName} registró entrada con ${minutesLate} minutos de retraso el ${moment(checkInTime).format('MMM D')}. Programado: ${slot.startTime}, Real: ${moment(checkInTime).format('h:mm A')}.`,
                    actionRequired: minutesLate > 30, // Only require action for significant lateness
                    minutes: minutesLate
                  });
                }
              }

              // 2. Check for missing check-outs
              if (record.checkInTime && !record.checkOutTime) {
                const checkInTime = record.checkInTime.toDate();
                const hoursActive = (now - checkInTime) / (1000 * 60 * 60);

                if (dateKey !== today && hoursActive > 12) {
                  // Missing check-out from a previous day
                  alerts.push({
                    id: `missing-checkout-${record.id}`,
                    type: 'Salida Faltante',
                    severity: 'error',
                    user: userName,
                    userId,
                    time: moment(checkInTime).format('MMM D, h:mm A'),
                    timestamp: checkInTime,
                    message: `${userName} registró entrada el ${moment(checkInTime).format('MMM D')} a las ${moment(checkInTime).format('h:mm A')} pero nunca registró salida.`,
                    actionRequired: true,
                    attendanceId: record.id
                  });
                } else if (dateKey === today) {
                  // For today, only alert if it's past the scheduled end time
                  if (record.slotIndex !== undefined && timeSlots[record.slotIndex]) {
                    const slot = timeSlots[record.slotIndex];
                    const endTimeParts = slot.endTime.split(':');
                    const scheduledEnd = new Date(scheduleDate);
                    scheduledEnd.setHours(parseInt(endTimeParts[0]), parseInt(endTimeParts[1]), 0, 0);

                    if (now > scheduledEnd && now.getTime() - scheduledEnd.getTime() > 30 * 60 * 1000) { // 30 min grace period
                      alerts.push({
                        id: `open-checkout-${record.id}`,
                        type: 'Salida Faltante',
                        severity: 'warning',
                        user: userName,
                        userId,
                        time: `Hoy - ${slot.endTime}`,
                        timestamp: now,
                        message: `${userName} tenía programado terminar a las ${slot.endTime} hoy pero aún no ha registrado salida.`,
                        actionRequired: true,
                        attendanceId: record.id
                      });
                    }
                  }
                }
              }

              // 3. Check for early check-outs
              if (record.checkInTime && record.checkOutTime && record.slotIndex !== undefined && timeSlots[record.slotIndex]) {
                const slot = timeSlots[record.slotIndex];
                const endTimeParts = slot.endTime.split(':');
                const scheduledEnd = new Date(scheduleDate);
                scheduledEnd.setHours(parseInt(endTimeParts[0]), parseInt(endTimeParts[1]), 0, 0);

                const checkOutTime = record.checkOutTime.toDate();
                const minutesEarly = Math.round((scheduledEnd - checkOutTime) / (1000 * 60));

                if (minutesEarly > 15) { // Left over 15 minutes early
                  alerts.push({
                    id: `early-${record.id}`,
                    type: 'Salida Temprana',
                    severity: minutesEarly > 60 ? 'warning' : 'info',
                    user: userName,
                    userId,
                    time: moment(checkOutTime).format('MMM D, h:mm A'),
                    timestamp: checkOutTime,
                    message: `${userName} registró salida ${minutesEarly} minutos antes el ${moment(checkOutTime).format('MMM D')}. Programado: ${slot.endTime}, Real: ${moment(checkOutTime).format('h:mm A')}.`,
                    actionRequired: minutesEarly > 60, // Only require action for significant early departure
                    minutes: minutesEarly
                  });
                }
              }

              // 4. Check for long lunch breaks
              if (record.lunchStartTime && record.lunchEndTime) {
                const lunchStart = record.lunchStartTime.toDate();
                const lunchEnd = record.lunchEndTime.toDate();
                const lunchDuration = (lunchEnd - lunchStart) / (1000 * 60);

                if (lunchDuration > 60) { // Over 60 minute lunch
                  alerts.push({
                    id: `long-lunch-${record.id}`,
                    type: 'Descanso Largo',
                    severity: lunchDuration > 90 ? 'warning' : 'info',
                    user: userName,
                    userId,
                    time: moment(lunchStart).format('MMM D, h:mm A'),
                    timestamp: lunchStart,
                    message: `${userName} tomó un descanso de ${Math.round(lunchDuration)} minutos el ${moment(lunchStart).format('MMM D')} (el estándar es 60 minutos).`,
                    actionRequired: lunchDuration > 90, // Only require action for very long breaks
                    minutes: Math.round(lunchDuration)
                  });
                }
              }
            });
          }
        }

        // Add alerts for consistent late arrivals
        Object.entries(lateArrivalsCount).forEach(([userId, data]) => {
          if (data.count >= 3) {
            alerts.push({
              id: `consistent-late-${userId}`,
              type: 'Llegadas Tardías Consistentes',
              severity: 'error',
              user: data.userName,
              userId,
              time: moment().format('MMM D'),
              timestamp: new Date(),
              message: `${data.userName} ha llegado tarde ${data.count} veces en ${this.alertFilter.period === 'today' ? 'el día' : this.alertFilter.period === 'week' ? 'la semana' : 'el mes'}.`,
              actionRequired: true,
              count: data.count
            });
          }
        });

        // Sort alerts by timestamp (newest first) and severity
        alerts.sort((a, b) => {
          // First by severity
          const severityOrder = { error: 0, warning: 1, info: 2 };
          const severityDiff = severityOrder[a.severity] - severityOrder[b.severity];
          if (severityDiff !== 0) return severityDiff;

          // Then by actionRequired (required actions first)
          if (a.actionRequired && !b.actionRequired) return -1;
          if (!a.actionRequired && b.actionRequired) return 1;

          // Then by timestamp (newest first)
          return b.timestamp - a.timestamp;
        });

        console.log(`Generated ${alerts.length} alerts`);
        this.alerts = alerts;
      } catch (error) {
        console.error('Error fetching alerts:', error);
        this.$emit('error', 'Error al cargar las alertas');
      } finally {
        this.loading.alerts = false;
      }
    },
    async generateReport() {
      this.loading.reports = true;
      try {
        let reportResults = [];

        const reportFilter = this.reportFilter;
        if (reportFilter.dateRange === 'today') {
          reportFilter.startDate = moment().startOf('day').toDate();
          reportFilter.endDate = moment().endOf('day').toDate();
        } else if (reportFilter.dateRange === 'yesterday') {
          reportFilter.startDate = moment().subtract(1, 'day').startOf('day').toDate();
          reportFilter.endDate = moment().subtract(1, 'day').endOf('day').toDate();
        } else if (reportFilter.dateRange === 'thisWeek') {
          reportFilter.startDate = moment().startOf('isoWeek').toDate();
          reportFilter.endDate = moment().endOf('week').toDate();
        } else if (reportFilter.dateRange === 'lastWeek') {
          reportFilter.startDate = moment().subtract(1, 'week').startOf('week').toDate();
          reportFilter.endDate = moment().subtract(1, 'week').endOf('isoweek').toDate();
        } else if (reportFilter.dateRange === 'thisMonth') {
          reportFilter.startDate = moment().startOf('month').toDate();
          reportFilter.endDate = moment().endOf('month').toDate();
        } else if (reportFilter.dateRange === 'custom') {
          reportFilter.startDate = moment(reportFilter.startDate).startOf('day').toDate();
          reportFilter.endDate = moment(reportFilter.endDate).endOf('day').toDate();
        }

        // Determine the date range for the report
        const reportStart = moment(reportFilter.startDate).startOf('day').toDate();
        const reportEnd = moment(reportFilter.endDate).endOf('day').toDate();

        // Step 1: First fetch all schedules in the date range
        const schedulesQuery = query(
          collection(db, 'schedulesConfig'),
          where('date', '>=', reportStart),
          where('date', '<=', reportEnd)
        );

        const schedulesSnapshot = await getDocs(schedulesQuery);
        const schedules = schedulesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        // Organize schedules by userId and date
        const schedulesByUserAndDate = {};
        const userAttendanceStats = {}; // Track attendance stats per user

        schedules.forEach(schedule => {
          const userId = schedule.userId;
          // Use userMap as the primary source of truth for user names
          const userName = this.userMap[userId] || schedule.userName || 'Unknown User';
          const scheduleDate = schedule.date.toDate();
          const dateKey = moment(scheduleDate).format('YYYY-MM-DD');

          // Initialize user stats if not exists
          if (!userAttendanceStats[userId]) {
            userAttendanceStats[userId] = {
              userId,
              userName, // Now using the better name source
              scheduledDays: new Set(),
              attendedDays: new Set(),
              totalScheduledShifts: 0,
              totalAttendedShifts: 0,
              missedShifts: 0,
              lateArrivals: 0,
              earlyDepartures: 0
            };
          }

          // Add this scheduled day
          userAttendanceStats[userId].scheduledDays.add(dateKey);

          // Count scheduled shifts
          const timeSlots = schedule.timeSlots || [{
            startTime: schedule.startTime || '09:00',
            endTime: schedule.endTime || '17:00'
          }];

          userAttendanceStats[userId].totalScheduledShifts += timeSlots.length;

          // Store schedule by user and date
          if (!schedulesByUserAndDate[userId]) {
            schedulesByUserAndDate[userId] = {};
          }

          schedulesByUserAndDate[userId][dateKey] = schedule;
        });

        // Step 2: Fetch attendance records for the same period
        const attendanceQuery = query(
          collection(db, 'attendance'),
          where('date', '>=', reportStart),
          where('date', '<=', reportEnd),
          orderBy('date', 'desc')
        );

        const attendanceSnapshot = await getDocs(attendanceQuery);

        // Group attendance records by user and date
        const attendanceByUserAndDate = {};

        attendanceSnapshot.docs.forEach(doc => {
          const data = doc.data();
          if (!data.date) return;

          const userId = data.userId;
          const attendanceDate = data.date.toDate();
          const dateKey = moment(attendanceDate).format('YYYY-MM-DD');

          if (!attendanceByUserAndDate[userId]) {
            attendanceByUserAndDate[userId] = {};
          }

          if (!attendanceByUserAndDate[userId][dateKey]) {
            attendanceByUserAndDate[userId][dateKey] = [];
          }

          attendanceByUserAndDate[userId][dateKey].push({
            id: doc.id,
            ...data
          });

          // Update user stats
          if (userAttendanceStats[userId]) {
            userAttendanceStats[userId].attendedDays.add(dateKey);
            userAttendanceStats[userId].totalAttendedShifts += 1;
          }
        });

        // Step 3: Process attendance records and add to report
        for (const userId in attendanceByUserAndDate) {
          for (const dateKey in attendanceByUserAndDate[userId]) {
            const records = attendanceByUserAndDate[userId][dateKey];
            const schedule = schedulesByUserAndDate[userId]?.[dateKey];

            // Process each attendance record
            for (const data of records) {
              // Skip if data is invalid
              if (!data || !data.id) {
                console.error('Missing data or data.id for attendance record:', data);
                continue;
              }

              let attendanceDate = data.date ? data.date.toDate() : new Date();

              // Get check-in and check-out times
              let checkInTime = data.checkInTime ? data.checkInTime.toDate() : null;
              let checkOutTime = data.checkOutTime ? data.checkOutTime.toDate() : null;

              // Get time slots from schedule
              const timeSlots = schedule ? (schedule.timeSlots || [{
                startTime: schedule.startTime || '09:00',
                endTime: schedule.endTime || '17:00'
              }]) : [];

              // Find the appropriate time slot for this attendance record
              let matchingSlot = null;

              if (checkInTime && timeSlots.length > 0) {
                // If we have a slotIndex saved in the attendance record, use that
                if (data.slotIndex !== undefined && timeSlots[data.slotIndex]) {
                  matchingSlot = timeSlots[data.slotIndex];
                } else {
                  // Otherwise find the closest time slot to the check-in time
                  let minTimeDiff = Infinity;

                  timeSlots.forEach(slot => {
                    const startTimeParts = slot.startTime.split(':');
                    const scheduledStart = new Date(attendanceDate);
                    scheduledStart.setHours(parseInt(startTimeParts[0]), parseInt(startTimeParts[1]), 0, 0);

                    const timeDiff = Math.abs(checkInTime - scheduledStart);
                    if (timeDiff < minTimeDiff) {
                      minTimeDiff = timeDiff;
                      matchingSlot = slot;
                    }
                  });
                }
              }

              // For report display, use the first and last slots to show the full range
              const firstSlot = timeSlots[0] || { startTime: '09:00', endTime: '17:00' };
              const lastSlot = timeSlots[timeSlots.length - 1] || firstSlot;

              // Calculate adherence
              let adherence = 'N/A';
              let adherenceClass = '';

              if (!schedule && checkInTime) {
                // This is an unscheduled entry
                adherence = 'Sin Programación';
                adherenceClass = 'error';
                
                // Track stats if we have user data
                if (userAttendanceStats[userId]) {
                  if (!userAttendanceStats[userId].unscheduledEntries) {
                    userAttendanceStats[userId].unscheduledEntries = 0;
                  }
                  userAttendanceStats[userId].unscheduledEntries++;
                }
              } else if (schedule && checkInTime) {
                if (!checkOutTime) {
                  adherence = 'No Checkout';
                  adherenceClass = 'warning';

                  // Track stats
                  if (userAttendanceStats[userId]) {
                    userAttendanceStats[userId].missedShifts += 0.5; // Half missing since they at least checked in
                  }
                } else {
                  // Calculate adherence based on matching slot
                  if (matchingSlot) {
                    const startTimeParts = matchingSlot.startTime.split(':');
                    const endTimeParts = matchingSlot.endTime.split(':');

                    const scheduledStart = new Date(attendanceDate);
                    scheduledStart.setHours(parseInt(startTimeParts[0]), parseInt(startTimeParts[1]), 0, 0);

                    const scheduledEnd = new Date(attendanceDate);
                    scheduledEnd.setHours(parseInt(endTimeParts[0]), parseInt(endTimeParts[1]), 0, 0);

                    if (checkInTime > new Date(scheduledStart.getTime() + 15 * 60 * 1000)) {
                      adherence = 'LLegada tarde';
                      adherenceClass = 'warning';

                      // Track late arrivals
                      if (userAttendanceStats[userId]) {
                        userAttendanceStats[userId].lateArrivals += 1;
                      }
                    }
                    
                    if (checkOutTime < new Date(scheduledEnd.getTime() - 15 * 60 * 1000)) {
                      adherence = 'Salida temprana';
                      adherenceClass = 'warning';

                      // Track early departures
                      if (userAttendanceStats[userId]) {
                        userAttendanceStats[userId].earlyDepartures += 1;
                      }
                    }
                    
                    if (!(checkInTime > new Date(scheduledStart.getTime() + 15 * 60 * 1000)) && 
                        !(checkOutTime < new Date(scheduledEnd.getTime() - 15 * 60 * 1000))) {
                      adherence = 'OK';
                      adherenceClass = 'good';
                    }
                  }
                }
              }

              // Is this entry already resolved?
              const isResolved = data.resolvedUnscheduled || false;

              // Calculate break time and excess break time
              let actualBreakMinutes = 0;
              let allowedBreakTime = 0;
              let excessBreakTime = 0;

              if (data.lunchStartTime && data.lunchEndTime) {
                actualBreakMinutes = Math.round((data.lunchEndTime.toDate() - data.lunchStartTime.toDate()) / (1000 * 60));
                
                // Get the allowed break time from the matching slot or data
                if (matchingSlot && matchingSlot.breakDuration) {
                  allowedBreakTime = matchingSlot.breakDuration;
                } else if (data.breakDuration) {
                  allowedBreakTime = data.breakDuration;
                } else if (data.scheduledShift && data.scheduledShift.breakDuration) {
                  allowedBreakTime = data.scheduledShift.breakDuration;
                } else {
                  allowedBreakTime = 30; // Default value
                }
                
                // Calculate excess break time
                excessBreakTime = Math.max(0, actualBreakMinutes - allowedBreakTime);
              }

              // Create the report record
              reportResults.push({
                id: data.id, // Ensure this is the document ID
                userName: data.userName || this.userMap[data.userId] || 'Unknown User',
                userId: data.userId,
                date: moment(attendanceDate).format('YYYY-MM-DD'),
                checkIn: checkInTime ? checkInTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : 'N/A',
                checkOut: checkOutTime ? checkOutTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : 'N/A',
                lunchDuration: data.lunchStartTime && data.lunchEndTime ?
                  `${actualBreakMinutes} min` : 'N/A',
                allowedBreakTime,
                excessBreakTime,
                totalHours: data.totalWorkHours ? data.totalWorkHours.toFixed(2) : 'N/A',
                scheduledShift: matchingSlot ? `${matchingSlot.startTime} - ${matchingSlot.endTime}` : 
                                adherence === 'Sin Programación' ? 'No Asignado' : 'N/A',
                scheduledRange: timeSlots.length > 1 ?
                  `${firstSlot.startTime} - ${lastSlot.endTime} (${timeSlots.length} shifts)` :
                  timeSlots.length === 1 ? `${firstSlot.startTime} - ${firstSlot.endTime}` : 'No Programado',
                adherence,
                adherenceClass,
                slotIndex: data.slotIndex !== undefined ? data.slotIndex + 1 : 'N/A',
                totalSlots: timeSlots.length,
                recordType: 'attendance',
                isResolved,
                checkInTime, // Keep raw value for sorting
                checkOutTime // Keep raw value for sorting
              });
            }
          }
        }

        // Step 4: Find schedules without corresponding attendance records (no-shows)
        for (const userId in schedulesByUserAndDate) {
          for (const dateKey in schedulesByUserAndDate[userId]) {
            const schedule = schedulesByUserAndDate[userId][dateKey];
            const hasAttendance = attendanceByUserAndDate[userId]?.[dateKey]?.length > 0;

            // Only process past dates (can't be a no-show for future shifts)
            const scheduleDate = schedule.date.toDate();
            const isDateInPast = scheduleDate < new Date();

            if (isDateInPast && !hasAttendance) {
              // This is a no-show - add to the report
              const timeSlots = schedule.timeSlots || [{
                startTime: schedule.startTime || '09:00',
                endTime: schedule.endTime || '17:00'
              }];

              // Track missed shifts in stats
              if (userAttendanceStats[userId]) {
                userAttendanceStats[userId].missedShifts += timeSlots.length;
              }

              // Add an entry for each missed time slot
              timeSlots.forEach((slot, index) => {
                reportResults.push({
                  id: `no-show-${userId}-${dateKey}-${index}`,
                  userName: schedule.userName || this.userMap[userId] || 'Unknown User',
                  userId: userId,
                  date: scheduleDate.toLocaleDateString(),
                  checkIn: 'No Show',
                  checkOut: 'No Show',
                  lunchDuration: 'N/A',
                  totalHours: '0.00',
                  scheduledShift: `${slot.startTime} - ${slot.endTime}`,
                  scheduledRange: timeSlots.length > 1 ?
                    `${timeSlots[0].startTime} - ${timeSlots[timeSlots.length - 1].endTime} (${timeSlots.length} shifts)` :
                    `${timeSlots[0].startTime} - ${timeSlots[0].endTime}`,
                  adherence: 'Absent',
                  adherenceClass: '',
                  slotIndex: index + 1,
                  totalSlots: timeSlots.length,
                  recordType: 'no-show'
                });
              });
            }
          }
        }

        // Apply user filter if specified
        if (this.reportFilter.users.length > 0) {
          reportResults = reportResults.filter(record =>
            this.reportFilter.users.includes(record.userId)
          );
        }

        // Sort results
        reportResults.sort((a, b) => {
          // Summaries at the very top
          if (a.recordType === 'summary' && b.recordType !== 'summary') return -1;
          if (a.recordType !== 'summary' && b.recordType === 'summary') return 1;

          // Then by date (newest first for regular entries)
          if (a.recordType !== 'summary' && b.recordType !== 'summary') {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            if (dateB - dateA !== 0) return dateB - dateA;
          }

          // Then by user name
          const userCompare = a.userName.localeCompare(b.userName);
          if (userCompare !== 0) return userCompare;

          // Then by record type (no-shows after attendance)
          if (a.recordType === 'no-show' && b.recordType === 'attendance') return 1;
          if (a.recordType === 'attendance' && b.recordType === 'no-show') return -1;

          return 0;
        });

        this.reportData = reportResults;
      } catch (error) {
        console.error('Error generating report:', error);
        this.$emit('error', 'Failed to generate report');
      } finally {
        this.loading.reports = false;
      }
    },
    async forceCheckout(alert) {
      if (!confirm(`¿Está seguro que desea forzar la salida de ${alert.user}?`)) {
        return;
      }

      try {
        // Get the attendance record
        const attendanceRef = doc(db, 'attendance', alert.attendanceId);

        // Update the record with the checkout time
        await updateDoc(attendanceRef, {
          checkOutTime: serverTimestamp(),
          status: 'Checked Out',
          totalWorkHours: 8, // Default to 8 hours for forced checkout
          adminForced: true,
          adminForcedAt: serverTimestamp()
        });

        // Mark the alert as resolved
        this.resolvedAlerts.add(alert.id);

        this.$emit('success', `Forced checkout completed for ${alert.user}`);
      } catch (error) {
        console.error('Error forcing checkout:', error);
        this.$emit('error', 'Failed to force checkout');
      }
    },
    getStatusColor(status) {
      if (status === 'Trabajando') return 'success';
      if (status === 'En Descanso') return 'warning';
      if (status === 'No ha Registrado Entrada') return 'grey';
      if (status === 'Debe Trabajar Hoy') return 'error';
      if (status === 'No Programado Hoy') return 'grey darken-1';
      if (status === 'Registró Salida' || status.includes('Completó')) return 'info';
      return '';
    },

    getReportHeaders() {
      return [
        { text: 'Fecha', value: 'date' },
        { text: 'Empleado', value: 'userName' },
        { text: 'Entrada', value: 'checkIn' },
        { text: 'Salida', value: 'checkOut' },
        { text: 'Turno', value: 'scheduledShift' },
        { text: 'Horas', value: 'totalHours' },
        { text: 'Duración Descanso', value: 'lunchDuration' },
        { text: 'Cumplimiento', value: 'adherence' },
        { text: 'Acciones', value: 'actions', sortable: false }
      ];
    },

    getReportRowClass(item) {
      if (item.recordType === 'no-show') {
        return 'no-show-row';
      }
      return '';
    },

    getAlertColor(severity) {
      if (severity === 'error') return 'error';
      if (severity === 'warning') return 'warning';
      if (severity === 'info') return 'info';
      return '';
    },

    getAlertChipColor(severity) {
      if (severity === 'error') return 'error';
      if (severity === 'warning') return 'warning';
      if (severity === 'info') return 'info';
      return '';
    },

    getAlertSeverityText(severity) {
      if (severity === 'error') return 'Crítico';
      if (severity === 'warning') return 'Advertencia';
      if (severity === 'info') return 'Info';
      return '';
    },

    getMetricColor(minutes) {
      if (minutes > 60) return 'error';
      if (minutes > 30) return 'warning';
      return 'info';
    },

    downloadReport(format) {
      if (this.reportData.length === 0) {
        this.$emit('error', 'No hay datos para descargar');
        return;
      }

      if (format === 'csv') {
        const headers = Object.keys(this.reportData[0]);
        const csvContent = [
          headers.join(','),
          ...this.reportData.map(row =>
            headers.map(key => {
              let value = row[key];
              if (typeof value === 'string' && (value.includes(',') || value.includes('"'))) {
                value = `"${value.replace(/"/g, '""')}"`;
              }
              return value;
            }).join(',')
          )
        ].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `workforce_report_${moment().format('YYYY-MM-DD')}.csv`);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } else if (format === 'pdf') {
        alert('Descarga de PDF aún no implementada');
      }
    },

    handleEditSchedule(schedule) {
      // Reset the scheduleTemplate
      this.scheduleTemplate = {
        days: [],
        startDate: moment(schedule.date.toDate()).format('YYYY-MM-DD'),
        endDate: moment(schedule.date.toDate()).format('YYYY-MM-DD')
      };
      
      // Set the time slots from the selected schedule
      this.scheduleSlots = [...(schedule.timeSlots || [])];
      
      // If there are no time slots, add a default one
      if (this.scheduleSlots.length === 0) {
        this.scheduleSlots = [{
          startTime: '09:00',
          endTime: '17:00',
          hasBreak: false,
          breakDuration: 60
        }];
      }
      
      // Handle days of the week
      this.scheduleTemplate.days = [moment(schedule.date.toDate()).day()];
      
      // Scroll to the editor if needed
      this.$nextTick(() => {
        const editorElement = this.$el.querySelector('.schedule-editor');
        if (editorElement) {
          editorElement.scrollIntoView({ behavior: 'smooth' });
        }
      });
    },

    async handleDeleteSchedule({ id, date }) {
      try {
        this.loading.schedule = true;
        await deleteDoc(doc(db, 'schedulesConfig', id));
        
        // Refresh the calendar
        if (this.$refs.adminCalendar) {
          this.$refs.adminCalendar.refresh();
        }
        
        this.$notify({
          type: 'success',
          title: 'Horario Eliminado',
          text: `El horario del ${moment(date).format('DD/MM/YYYY')} ha sido eliminado exitosamente`
        });
      } catch (error) {
        console.error('Error deleting schedule:', error);
        this.$notify({
          type: 'error',
          title: 'Error',
          text: 'Hubo un error al eliminar el horario'
        });
      } finally {
        this.loading.schedule = false;
      }
    },

    formatEditDate() {
      return moment(this.scheduleTemplate.startDate).format('YYYY-MM-DD');
    },

    handleDateSelect(dateInfo) {
      console.log('Date selected:', dateInfo);
      
      // Update only the date and day selection (not entering edit mode)
      const selectedDate = moment(dateInfo.date).format('YYYY-MM-DD');
      
      // Set the selected date in both start and end fields
      this.scheduleTemplate.startDate = selectedDate;
      this.scheduleTemplate.endDate = selectedDate;
      
      // Select the day of week
      this.scheduleTemplate.days = [dateInfo.dayOfWeek];
      
      // Scroll to the editor section
      this.$nextTick(() => {
        const editorElement = this.$el.querySelector('.v-card-text');
        if (editorElement) {
          // Add a brief highlight to indicate the selection
          editorElement.classList.add('day-selected-flash');
          setTimeout(() => {
            editorElement.classList.remove('day-selected-flash');
          }, 1000);
          
          // Scroll to editor
          editorElement.scrollIntoView({ behavior: 'smooth' });
        }
      });
    },

    openManualCheckInDialog() {
      this.manualCheckIn = {
        userId: null,
        date: moment().format('YYYY-MM-DD'),
        time: moment().format('HH:mm'),
        type: 'checkIn',
        note: ''
      };
      debugger;
      this.manualCheckInDialog = true;
    },

    closeManualCheckInDialog() {
      this.manualCheckInDialog = false;
    },

    async submitManualCheckIn() {
      // Validate input
      if (!this.manualCheckIn.userId || !this.manualCheckIn.date || !this.manualCheckIn.time || !this.manualCheckIn.note) {
        this.$notify({
          type: 'error',
          title: 'Error',
          text: 'Por favor complete todos los campos requeridos'
        });
        return;
      }

      this.loading.manualCheckIn = true;
      try {
        const db = getFirestore();
        const user = this.users.find(u => u.id === this.manualCheckIn.userId);

        // Create datetime object
        const checkTime = moment(this.manualCheckIn.date+' '+this.manualCheckIn.time,'YYYY-MM-DD HH:mm').toDate();

        // Check if an attendance record exists for this date/user
        const attendanceQuery = query(
          collection(db, 'attendance'),
          where('userId', '==', this.manualCheckIn.userId),
          where('date', '>=', moment(this.manualCheckIn.date).startOf('day').toDate()),
          where('date', '<=', moment(this.manualCheckIn.date).endOf('day').toDate())
        );

        const attendanceSnapshot = await getDocs(attendanceQuery);
        let attendanceRef;
        let existingData = {};

        if (attendanceSnapshot.empty) {
          // Create new attendance record
          attendanceRef = doc(collection(db, 'attendance'));
          existingData = {
            userId: this.manualCheckIn.userId,
            userName: user.name,
            date: moment(this.manualCheckIn.date).startOf('day').toDate(),
            createdAt: serverTimestamp(),
            manualEntry: true,
            manualNote: this.manualCheckIn.note
          };
        } else {
          // Use existing record
          attendanceRef = attendanceSnapshot.docs[0].ref;
          existingData = attendanceSnapshot.docs[0].data();
        }

        // Update with the appropriate check type
        const updateData = { ...existingData };
        
        switch (this.manualCheckIn.type) {
          case 'checkIn':
            updateData.checkInTime = Timestamp.fromDate(checkTime);
            updateData.status = 'Checked In';
            break;
          case 'checkOut':
            updateData.checkOutTime = Timestamp.fromDate(checkTime);
            updateData.status = 'Checked Out';
            
            // Calculate total work hours if check-in exists
            if (existingData.checkInTime) {
              const checkInTime = existingData.checkInTime.toDate();
              let totalMinutes = (checkTime - checkInTime) / (1000 * 60);
              
              // Subtract lunch break if taken
              if (existingData.lunchStartTime && existingData.lunchEndTime) {
                const lunchStart = existingData.lunchStartTime.toDate();
                const lunchEnd = existingData.lunchEndTime.toDate();
                const lunchMinutes = (lunchEnd - lunchStart) / (1000 * 60);
                totalMinutes -= lunchMinutes;
              }
              
              updateData.totalWorkHours = Math.max(0, totalMinutes / 60);
            }
            break;
          case 'lunchStart':
            updateData.lunchStartTime = Timestamp.fromDate(checkTime);
            updateData.status = 'On Break';
            break;
          case 'lunchEnd':
            updateData.lunchEndTime = Timestamp.fromDate(checkTime);
            updateData.status = 'Checked In';
            break;
        }

        // Add manual flag to the specific field
        const fieldMap = {
          'checkIn': 'manualCheckIn',
          'checkOut': 'manualCheckOut',
          'lunchStart': 'manualLunchStart',
          'lunchEnd': 'manualLunchEnd'
        };

        updateData[fieldMap[this.manualCheckIn.type]] = true;

        // Update or create the attendance record
        await setDoc(attendanceRef, updateData);

        this.$notify({
          type: 'success',
          title: 'Registro Manual Guardado',
          text: 'Se ha registrado la asistencia correctamente'
        });

        // Close dialog and refresh data
        this.closeManualCheckInDialog();
        this.generateReport();
      } catch (error) {
        console.error('Error submitting manual check-in:', error);
        this.$notify({
          type: 'error',
          title: 'Error',
          text: 'Hubo un error al guardar el registro manual'
        });
      } finally {
        this.loading.manualCheckIn = false;
      }
    },

    async performManualCheckIn(item) {
      // Set up data for manual check-in
      this.manualCheckIn = {
        userId: item.userId,
        date: item.date.split('/').reverse().join('-'), // Convert from DD/MM/YYYY to YYYY-MM-DD
        time: item.scheduledShift.split(' - ')[0], // Use scheduled start time
        type: 'checkIn',
        note: 'Entrada manual para turno programado.'
      };
      debugger;
      this.manualCheckInDialog = true;
    },

    async manualCheckout(item) {
      // Set up data for manual checkout
      let endTime = '';
      
      // Try to get the end time from the scheduled shift
      if (item.scheduledShift && item.scheduledShift.includes(' - ')) {
        // Extract end time from shift format "HH:MM - HH:MM"
        endTime = item.scheduledShift.split(' - ')[1];
      } else {
        // If no scheduled shift end time is available, use current time
        endTime = moment().format('HH:mm');
      }
      
      this.manualCheckIn = {
        userId: item.userId,
        date: item.date.split('/').reverse().join('-'), // Convert from DD/MM/YYYY to YYYY-MM-DD
        time: endTime,
        type: 'checkOut',
        note: 'Salida manual registrada por administrador.'
      };
      debugger;
      
      this.manualCheckInDialog = true;
    },

    handleUnscheduledEntry(item) {
      // Set up data for unscheduled entry dialog
      this.unscheduledEntry = {
        id: item.id,
        userId: item.userId,
        userName: item.userName,
        date: item.date,
        checkIn: item.checkIn,
        action: 'useShift',
        shiftId: null,
        startTime: item.checkIn, // Default to check-in time
        endTime: moment(item.checkIn, 'HH:mm:ss').add(8, 'hours').format('HH:mm'), // Default to 8 hour shift
        hasBreak: true,
        breakDuration: 60
      };
      
      this.unscheduledEntryDialog = true;
    },

    async assignScheduleToEntry() {
      if (!this.unscheduledEntry.userId) return;
      
      this.loading.assignSchedule = true;
      try {
        const db = getFirestore();
        const batch = writeBatch(db);
        
        let timeSlots = [];
        const entryDate = moment(this.unscheduledEntry.date, 'MM/DD/YYYY').toDate();
        
        if (this.unscheduledEntry.action === 'useShift') {
          // Get shift details
          if (!this.unscheduledEntry.shiftId) {
            this.$notify({
              type: 'error',
              title: 'Error',
              text: 'Por favor seleccione un turno'
            });
            return;
          }
          
          const shiftDoc = await getDoc(doc(db, 'shifts', this.unscheduledEntry.shiftId));
          
          if (!shiftDoc.exists()) {
            this.$notify({
              type: 'error',
              title: 'Error', 
              text: 'El turno seleccionado no existe'
            });
            return;
          }
          
          const shiftData = shiftDoc.data();
          timeSlots = [{
            startTime: shiftData.startTime,
            endTime: shiftData.endTime,
            hasBreak: shiftData.hasBreak,
            breakDuration: shiftData.breakDuration
          }];
        } else {
          // Use custom schedule
          if (!this.unscheduledEntry.startTime || !this.unscheduledEntry.endTime) {
            this.$notify({
              type: 'error',
              title: 'Error',
              text: 'Por favor complete la hora de inicio y fin'
            });
            return;
          }
          
          timeSlots = [{
            startTime: this.unscheduledEntry.startTime,
            endTime: this.unscheduledEntry.endTime,
            hasBreak: this.unscheduledEntry.hasBreak,
            breakDuration: this.unscheduledEntry.hasBreak ? this.unscheduledEntry.breakDuration : 0
          }];
        }
        
        // Create schedule
        const scheduleRef = doc(collection(db, 'schedulesConfig'));
        
        batch.set(scheduleRef, {
          userId: this.unscheduledEntry.userId,
          date: moment(entryDate).startOf('day').toDate(),
          month: entryDate.getMonth(),
          year: entryDate.getFullYear(),
          timeSlots,
          createdAt: serverTimestamp(),
          createdForUnscheduledEntry: true
        });
        
        // Update the attendance record to link it to the schedule
        const attendanceRef = doc(db, 'attendance', this.unscheduledEntry.id);
        
        batch.update(attendanceRef, {
          scheduleId: scheduleRef.id,
          slotIndex: 0, // First slot
          resolvedUnscheduled: true,
          updatedAt: serverTimestamp()
        });
        
        await batch.commit();
        
        this.$notify({
          type: 'success',
          title: 'Turno Asignado',
          text: 'Se ha asignado el turno correctamente'
        });
        
        // Close dialog and refresh data
        this.unscheduledEntryDialog = false;
        this.generateReport();
      } catch (error) {
        console.error('Error assigning schedule to entry:', error);
        this.$notify({
          type: 'error',
          title: 'Error',
          text: 'Hubo un error al asignar el turno'
        });
      } finally {
        this.loading.assignSchedule = false;
      }
    },

    async generatePayrollReport() {
      this.loading.payroll = true;
      try {
        // Determine date range for payroll
        let startDate, endDate;
        
        switch (this.payrollFilter.period) {
          case 'thisWeek':
            startDate = moment().startOf('week').toDate();
            endDate = moment().endOf('week').toDate();
            break;
          case 'lastWeek':
            startDate = moment().subtract(1, 'weeks').startOf('week').toDate();
            endDate = moment().subtract(1, 'weeks').endOf('week').toDate();
            break;
          case 'thisMonth':
            startDate = moment().startOf('month').toDate();
            endDate = moment().endOf('month').toDate();
            break;
          case 'lastMonth':
            startDate = moment().subtract(1, 'months').startOf('month').toDate();
            endDate = moment().subtract(1, 'months').endOf('month').toDate();
            break;
          case 'custom':
            startDate = moment(this.payrollFilter.startDate).startOf('day').toDate();
            endDate = moment(this.payrollFilter.endDate).endOf('day').toDate();
            break;
          default:
            startDate = moment().startOf('month').toDate();
            endDate = moment().endOf('month').toDate();
        }
        
        // Fetch all attendance records in date range
        const attendanceQuery = query(
          collection(db, 'attendance'),
          where('date', '>=', startDate),
          where('date', '<=', endDate),
          orderBy('date', 'desc')
        );
        
        const attendanceSnapshot = await getDocs(attendanceQuery);
        
        // Group attendance by user
        const attendanceByUser = {};
        
        attendanceSnapshot.docs.forEach(doc => {
          const data = doc.data();
          if (!data.userId) return;
          
          if (!attendanceByUser[data.userId]) {
            attendanceByUser[data.userId] = [];
          }
          
          attendanceByUser[data.userId].push({
            id: doc.id,
            ...data
          });
        });
        
        // Process each user's attendance into payroll
        const payrollResults = [];
        
        for (const userId in attendanceByUser) {
          const userAttendance = attendanceByUser[userId];
          const userName = this.userMap[userId] || userAttendance[0]?.userName || 'Unknown User';
          
          // Count days worked (unique dates with checkout times)
          const daysWorked = new Set();
          let totalHours = 0;
          let extraHours = 0;
          
          userAttendance.forEach(record => {
            if (record.checkOutTime && record.checkInTime) {
              const recordDate = moment(record.date.toDate()).format('YYYY-MM-DD');
              daysWorked.add(recordDate);
              
              // Calculate hours for this record
              const hours = record.totalWorkHours || 0;
              totalHours += hours;
              
              // Calculate extra hours (over 8 per day)
              if (hours > 8) {
                extraHours += (hours - 8);
              }
            }
          });
          
          // Calculate pay amount (placeholder - should be based on actual hourly rates)
          const hourlyRate = 10; // Placeholder hourly rate
          const regularHours = totalHours - extraHours;
          const regularPay = regularHours * hourlyRate;
          const extraPay = extraHours * (hourlyRate * 1.5); // 1.5x for overtime
          const totalPay = regularPay + extraPay;
          
          payrollResults.push({
            userId,
            userName,
            period: `${moment(startDate).format('DD/MM/YYYY')} - ${moment(endDate).format('DD/MM/YYYY')}`,
            daysWorked: daysWorked.size,
            totalHours: totalHours.toFixed(2),
            extraHours: extraHours.toFixed(2),
            hourlyRate: `$${hourlyRate.toFixed(2)}`,
            payAmount: `$${totalPay.toFixed(2)}`
          });
        }
        
        // Sort payroll by user name
        payrollResults.sort((a, b) => a.userName.localeCompare(b.userName));
        
        this.payrollData = payrollResults;
      } catch (error) {
        console.error('Error generating payroll report:', error);
        this.$notify({
          type: 'error',
          title: 'Error',
          text: 'Hubo un error al generar el reporte de remuneraciones'
        });
      } finally {
        this.loading.payroll = false;
      }
    },

    downloadPayroll(format) {
      if (this.payrollData.length === 0) {
        this.$notify({
          type: 'error',
          title: 'Error',
          text: 'No hay datos para descargar'
        });
        return;
      }
      
      if (format === 'csv') {
        const headers = Object.keys(this.payrollData[0]);
        const csvContent = [
          headers.join(','),
          ...this.payrollData.map(row =>
            headers.map(key => {
              let value = row[key];
              if (typeof value === 'string' && (value.includes(',') || value.includes('"'))) {
                value = `"${value.replace(/"/g, '""')}"`;
              }
              return value;
            }).join(',')
          )
        ].join('\n');
        
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `payroll_report_${moment().format('YYYY-MM-DD')}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } else if (format === 'pdf') {
        alert('Descarga de PDF aún no implementada');
      }
    },

    getPayrollHeaders() {
      return [
        { text: 'Empleado', value: 'userName' },
        { text: 'Período', value: 'period' },
        { text: 'Días Trabajados', value: 'daysWorked' },
        { text: 'Horas Totales', value: 'totalHours' },
        { text: 'Horas Extra', value: 'extraHours' },
        { text: 'Valor Hora', value: 'hourlyRate' },
        { text: 'Valor a Pagar', value: 'payAmount' }
      ];
    },

    returnDateFormated(date) {
      if (date.seconds)
        return moment(date.seconds * 1000).format('YYYY-MM-DD HH:mm');

      return moment(date).format('YYYY-MM-DD HH:mm');
    },
},
watch: {
'alertFilter.type'() {
  // No need to refetch, just let the computed property filter
},
'alertFilter.period'() {
  this.fetchAlerts();
},
'payrollFilter.period'() {
  this.generatePayrollReport();
}
},
async mounted() {
await this.fetchUsers();  // Wait for users to be loaded first
this.fetchShifts();       // Fetch shifts
this.fetchAlerts();       // Then fetch alerts
}
}
</script>

<style scoped>
.admin-panel-container {
width: 100%;
}

.panel-tabs {
display: flex;
border-bottom: 1px solid #e0e0e0;
margin-bottom: 20px;
}

.panel-tabs button {
padding: 10px 20px;
background: none;
border: none;
cursor: pointer;
font-weight: bold;
color: #757575;
}

.panel-tabs button.active {
color: #2196F3;
border-bottom: 2px solid #2196F3;
}

.search-bar {
margin-bottom: 20px;
}

.search-bar input {
width: 100%;
padding: 10px;
border: 1px solid #e0e0e0;
border-radius: 4px;
font-size: 16px;
}

.users-table {
width: 100%;
border-collapse: collapse;
}

.users-table th,
.users-table td {
padding: 12px;
text-align: left;
border-bottom: 1px solid #e0e0e0;
}

.users-table th {
background-color: #f5f5f5;
font-weight: bold;
}

.status-working {
color: #4CAF50;
font-weight: bold;
}

.status-on.lunch {
color: #FF9800;
font-weight: bold;
}

.status-checked.out {
color: #9E9E9E;
}

.status-not.checked.in {
color: #F44336;
}

.action-btn {
padding: 6px 12px;
border: none;
border-radius: 4px;
margin-right: 5px;
cursor: pointer;
}

.view-btn {
background-color: #2196F3;
color: white;
}

.edit-btn {
background-color: #FF9800;
color: white;
}

.schedule-editor {
  border-radius: 8px;
  padding: 16px;
  margin-top: 16px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  border: 1px solid #e9ecef;
}

.edit-mode {
  border-left: 4px solid #2196F3;
  padding-left: 12px;
  transition: all 0.3s ease;
}

.day-selector {
display: flex;
flex-wrap: wrap;
gap: 15px;
margin-bottom: 20px;
}

.time-slots {
display: flex;
flex-direction: column;
gap: 15px;
margin-bottom: 20px;
}

.time-slot {
display: flex;
gap: 15px;
align-items: center;
background-color: #f0f0f0;
padding: 10px;
border-radius: 4px;
}

.time-field,
.date-field {
display: flex;
flex-direction: column;
gap: 5px;
}

.time-field label,
.date-field label {
font-weight: bold;
}

.time-field input,
.date-field input {
padding: 8px;
border: 1px solid #e0e0e0;
border-radius: 4px;
}

.schedule-actions {
display: flex;
gap: 10px;
margin-top: 20px;
}

.apply-btn {
padding: 10px 20px;
background-color: #4CAF50;
color: white;
border: none;
border-radius: 4px;
cursor: pointer;
}

.cancel-btn {
padding: 10px 20px;
background-color: #F44336;
color: white;
border: none;
border-radius: 4px;
cursor: pointer;
}

.report-filters,
.alert-filters {
display: flex;
flex-wrap: wrap;
gap: 15px;
margin-bottom: 20px;
background-color: #f5f5f5;
padding: 15px;
border-radius: 8px;
}

.filter-group {
display: flex;
align-items: center;
gap: 10px;
}

.filter-group label {
font-weight: bold;
}

.filter-group select,
.filter-group input {
padding: 8px;
border: 1px solid #e0e0e0;
border-radius: 4px;
}

.generate-btn {
padding: 8px 16px;
background-color: #2196F3;
color: white;
border: none;
border-radius: 4px;
cursor: pointer;
}

.report-table {
width: 100%;
border-collapse: collapse;
margin-top: 20px;
}

.report-table th,
.report-table td {
padding: 12px;
text-align: left;
border-bottom: 1px solid #e0e0e0;
}

.report-table th {
background-color: #f5f5f5;
font-weight: bold;
}

.adherence-good {
color: #4CAF50;
}

.adherence-fair {
color: #FF9800;
}

.adherence-poor {
color: #F44336;
}

.report-actions {
display: flex;
gap: 10px;
margin-top: 20px;
}

.download-btn,
.print-btn {
padding: 10px 20px;
background-color: #2196F3;
color: white;
border: none;
border-radius: 4px;
cursor: pointer;
}

.print-btn {
background-color: #9E9E9E;
}

.alerts-list {
margin-top: 20px;
}

.alert-item {
background-color: #f9f9f9;
border-radius: 8px;
padding: 15px;
margin-bottom: 15px;
border-left: 5px solid #9E9E9E;
transition: all 0.2s ease;
}

.alert-item.resolved {
opacity: 0.6;
background-color: #f0f0f0;
}

.severity-error {
border-left-color: #F44336;
border-top: 1px solid #FFCDD2;
border-bottom: 1px solid #FFCDD2;
border-right: 1px solid #FFCDD2;
}

.severity-warning {
border-left-color: #FF9800;
border-top: 1px solid #FFE0B2;
border-bottom: 1px solid #FFE0B2;
border-right: 1px solid #FFE0B2;
}

.severity-info {
border-left-color: #2196F3;
border-top: 1px solid #BBDEFB;
border-bottom: 1px solid #BBDEFB;
border-right: 1px solid #BBDEFB;
}

.alert-header {
display: flex;
justify-content: space-between;
margin-bottom: 10px;
}

.alert-type-container {
display: flex;
align-items: center;
gap: 8px;
}

.alert-type {
font-weight: bold;
}

.alert-badge {
font-size: 0.8em;
padding: 3px 8px;
border-radius: 12px;
font-weight: bold;
}

.alert-badge.error {
background-color: #FFEBEE;
color: #D32F2F;
}

.alert-badge.warning {
background-color: #FFF3E0;
color: #EF6C00;
}

.alert-badge.info {
background-color: #E3F2FD;
color: #1976D2;
}

.alert-metrics {
background-color: #f5f5f5;
padding: 8px 12px;
border-radius: 6px;
margin-top: 10px;
display: inline-flex;
align-items: center;
gap: 8px;
}

.metric-label {
font-size: 0.9em;
color: #757575;
}

.metric-value {
font-weight: bold;
font-size: 1.1em;
}

.metric-value.critical {
color: #D32F2F;
}

.metric-value.warning {
color: #EF6C00;
}

.metric-value.minor {
color: #1976D2;
}

.resolve-btn {
padding: 8px 16px;
background-color: #4CAF50;
color: white;
border: none;
border-radius: 4px;
cursor: pointer;
transition: background-color 0.2s ease;
}

.resolve-btn:hover {
background-color: #388E3C;
}

.resolved-btn {
background-color: #9E9E9E;
}

.resolved-btn:hover {
background-color: #757575;
}

.action-btn {
padding: 8px 16px;
background-color: #673AB7;
color: white;
border: none;
border-radius: 4px;
cursor: pointer;
}

.action-btn:hover {
background-color: #5E35B1;
}

.no-alerts {
text-align: center;
padding: 20px;
color: #757575;
}

.add-slot-btn {
align-self: flex-start;
background-color: #2196F3;
color: white;
border: none;
border-radius: 4px;
padding: 8px 16px;
cursor: pointer;
display: flex;
align-items: center;
}

.remove-slot-btn {
background-color: #FF5252;
color: white;
border: none;
border-radius: 4px;
width: 36px;
height: 36px;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
}

.status-working {
color: #4CAF50;
font-weight: bold;
}

.status-lunch {
color: #FF9800;
font-weight: bold;
}

.status-not-checked-in {
color: #9E9E9E;
}

.status-should-work {
color: #F44336;
font-weight: bold;
}

.status-not-scheduled {
color: #757575;
}

.status-checked-out {
color: #2196F3;
}

.summary-row {
background-color: #EDE7F6;
font-weight: bold;
border-top: 1px solid #D1C4E9;
border-bottom: 1px solid #D1C4E9;
}

.total-hours {
font-weight: bold;
color: #2196F3;
}

.shift-number {
font-size: 0.8em;
color: #757575;
margin-left: 5px;
}

.no-show-row {
background-color: #FFF8E1;
color: #FF8F00;
}

.border-left-error {
border-left: 4px solid #F44336;
background-color: unset !important;
}

.good.border-left-error {
border-left: 4px solid #4CAF50;
background-color: unset !important;
}

.item-adherence.warning {
color: #FF9800;
background-color: unset !important;
}

.item-adherence.good {
color: #4CAF50;
}

.adherence-details {
font-size: 0.8em;
margin-top: 5px;
color: #757575;
}

.form-reset-flash {
  animation: reset-flash 1s ease;
}

@keyframes reset-flash {
  0% { background-color: #e3f2fd; }
  100% { background-color: transparent; }
}

.day-selected-flash {
  animation: day-selected-flash 1s ease;
}

@keyframes day-selected-flash {
  0% { background-color: #e3f2fd; }
  100% { background-color: transparent; }
}
</style>
